import {
    useContext,
    useEffect,
    useReducer,
    useState,
} from 'react';

import {
    Button,
    Card,
    Col,
    Container,
    Form,
    Row,
} from 'react-bootstrap';

import { PreviousNextButtons } from '../controls/buttons';
import { RadioControl } from '../controls/radio';
import { TextControl } from '../controls/text';
import {
    asPhoneNumber,
    isEmail,
    isPhoneNumber,
} from '../libs/contact';
import {
    BrandingContext,
    GeoContext,
    InsuranceDataContext,
    LayoutContext,
    NotificationsContext,
    SessionContext,
    stateReducer,
} from '../libs/contexts';
import { scrollUp } from '../libs/ui';

export const Address = () => {

    const { branding, setBranding } = useContext(BrandingContext);
    const { layout, setLayout } = useContext(LayoutContext);
    const { session, setSession } = useContext(SessionContext);

    const [updated, setUpdated] = useState(false);


    const validateScreen = ({ forceValidation }) => {



        if (forceValidation) {
            setLayout({
                lastValidated: "beneficiary"
            });
            setSession({ validationAttempted: Date.now() });
        }


    }

    useEffect(() => {

        scrollUp();
        setLayout({
            screenReady: false
        });

    }, []);

    useEffect(() => {

        if (updated && undefined !== layout.fields) {


            if (layout.fields && undefined === layout.fields[updated]) {
                let newFields = { ...layout.fields };
                newFields[updated] = "ok";
                setLayout({
                    fields: newFields
                });
            }

        }

    }, [updated, layout.fields]);

    const onChange = ({ id, value }) => {

        console.info(id, value);
        setUpdated(id);



    }

    return (
        <>
            <Container>
                <Row className="mt-4 mb-4">
                    <Col>
                        <h3>Tell us about yourself</h3>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col className="col-lg-12 col-xl-10">
                        <TextControl label="Province of residence" field="province_current" placeholder="Province" onChange={onChange} required={true} type="text" />
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col className="col-lg-12 col-xl-10">
                        <TextControl label="City" title="Please provide us with your city of residence" field="city" placeholder="City" onChange={onChange} required={true} type="text" />
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col className="col-lg-12 col-xl-10">
                        <TextControl label="Street address" title="Please provide us with your current address" field="address1" placeholder="Address" onChange={onChange} required={true} type="text" />
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col className="col-lg-12 col-xl-10">
                        <TextControl label="Postal code" title="Please provide us with your postal code" field="postal_code" placeholder="Postal code" onChange={onChange} required={true} type="text" />
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col className="col-lg-12 col-xl-10">
                        <TextControl label="Country" title="Please provide us with your country of residence" field="country" placeholder="Country" onChange={onChange} required={true} type="text" />
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col>
                        <RadioControl label="Were you born in your current province of residence?" type="yesno" onChange={onChange} field="province_check" />
                    </Col>
                </Row>
                {"No" === session.province_check &&
                    <Row className="mt-2">
                        <Col className="col-lg-12 col-xl-10">
                            <TextControl label="Province at birth" field="province_birth" placeholder="Province" onChange={onChange} required={true} type="text" />
                        </Col>
                    </Row>
                }
                <Row className="mt-4">
                    <Col>
                        {layout &&
                            <PreviousNextButtons enabled={layout.screenReady} callback={validateScreen} />
                        }
                    </Col>
                </Row>
            </Container>
        </>
    )

}
