import {
    React,
    useContext,
    useEffect,
    useState,
} from 'react';

import {
    Alert,
    Badge,
    Button,
    Col,
    Container,
    Form,
    InputGroup,
    Row,
} from 'react-bootstrap';
import { BsArrowLeftRight } from 'react-icons/bs';

import {
    LayoutContext,
    SessionContext,
} from '../libs/contexts';
import {
    toCm,
    toFeet,
    toKg,
    toLbs,
} from '../libs/units';

export const HeightControl = ({ hasErrors }) => {

    const { session, setSession } = useContext(SessionContext);

    const [buttonLabel, setButtonLabel] = useState("cm");

    useEffect(() => {

        if (undefined !== session.physical_units_height) {

            if ("metric" === session.physical_units_height) {
                if (undefined !== session.physical_cm) {
                    let cm = parseInt(session.physical_cm);
                    if (!isNaN(cm) && cm >= 100 && cm <= 300) {
                        let imperialValues = toFeet({ cm: cm });

                        setSession({
                            physical_cm: cm,
                            physical_feet: imperialValues.feet,
                            physical_inches: imperialValues.inches,
                            physical_height: cm + " cm"
                        });
                    } else {
                        console.info("Reset height");
                        setSession({
                            //physical_cm: '',
                            physical_feet: '',
                            physical_inches: '',
                            physical_height: ''
                        });
                    }
                }
                setButtonLabel("ft");
            } else {
                if (undefined !== session.physical_feet) {
                    let feet = parseInt(session.physical_feet);
                    console.info("Feet", feet);
                    if (!isNaN(feet) && feet >= 4 && feet <= 8) {
                        let inches = 0;
                        if (undefined !== session.physical_inches) {
                            let potentialInches = parseInt(session.physical_inches);
                            if (!isNaN(potentialInches) && potentialInches >= 1 && potentialInches <= 11) {
                                inches = potentialInches;
                            }
                        }
                        let cm = toCm({ feet: feet, inches: inches });
                        console.info("New cm", cm);

                        setSession({
                            physical_cm: cm,
                            physical_feet: feet,
                            physical_inches: inches,
                            physical_height: feet + "'" + inches
                        });
                    } else {
                        console.info("Reset height");
                        setSession({
                            physical_cm: '',
                            physical_feet: '',
                            physical_inches: '',
                            physical_height: ''
                        });
                    }
                }
                setButtonLabel("cm");
            }

        } else {
            setSession({
                physical_units_height: "imperial"
            });
        }


    }, [session.physical_units_height, session.physical_feet, session.physical_inches, session.physical_cm]);

    const toggleUnits = () => {
        let newValue;
        if ("metric" === session.physical_units_height) {
            newValue = "imperial";
        } else {
            newValue = "metric";
        }
        setSession({
            physical_units_height: newValue
        });

    }

    return (
        <Container fluid>
            <Row>
                <Col className="py-0 px-0">
                    <Form.Label>How tall are you?</Form.Label>
                </Col>
            </Row>
            <Row className='mb-2'>
                <Col className="col-md-9 py-0 px-0">
                    {"metric" === session.physical_units_height ?
                        <MetricHeightControl hasErrors={hasErrors} />
                        :
                        <ImperialHeightControl hasErrors={hasErrors} />
                    }
                </Col>
                <Col className="col-md-3 py-0 px-1 text-right text-end">
                    <Button title={"Switch to " + buttonLabel} variant="outline-info" onClick={toggleUnits}>
                        <BsArrowLeftRight /> {buttonLabel}
                    </Button>
                </Col>
            </Row>
        </Container>
    )

}

export const ImperialHeightControl = ({ hasErrors }) => {

    const { session, setSession } = useContext(SessionContext);

    const onChange = (e) => {

        let newValue = e.target.value.replace(/\D/g, '');
        switch (e.target.id) {
            case 'physical_inches':
                let possibleInches = '';
                if ('' !== newValue) {
                    possibleInches = parseInt(newValue);
                    if (isNaN(possibleInches) || possibleInches > 11) {
                        possibleInches = 0;
                    }

                }
                setSession({
                    physical_inches: possibleInches
                });
                break;

            case 'physical_feet':
                let possibleFeet = '';
                if ('' !== newValue) {
                    possibleFeet = parseInt(newValue);
                    if (isNaN(possibleFeet) || possibleFeet > 7 || possibleFeet < 4) {
                        possibleFeet = '';
                    }

                }
                setSession({
                    physical_feet: possibleFeet
                });
                break;
            default:
                break;
        }

    }

    return (

        <Container fluid>
            <Row>
                <Col className="col-md-6 py-0 px-0">
                    <InputGroup>
                        <InputGroup.Text>ft</InputGroup.Text>
                        <Form.Control onChange={onChange} value={session.physical_feet || ''} id="physical_feet" title="Height (feet)" className={hasErrors ? "is-invalid" : ""} type="text" placeholder="Feet" />
                    </InputGroup>
                </Col>
                <Col className="col-md-6 py-0 px-1">
                    <InputGroup>
                        <InputGroup.Text>in</InputGroup.Text>
                        <Form.Control onChange={onChange} value={session.physical_inches || ''} id="physical_inches" title="Height (inches)" className={hasErrors ? "is-invalid" : ""} type="text" placeholder="Inches" />
                    </InputGroup>
                </Col>
            </Row>
        </Container>

    );

}

export const MetricHeightControl = ({ hasErrors }) => {


    const { session, setSession } = useContext(SessionContext);


    const onChange = (e) => {

        let newValue = e.target.value.replace(/\D/g, '');
        console.info("Entered cm", newValue);
        switch (e.target.id) {
            case 'physical_cm':
                let possibleCm = '';
                if ('' !== newValue) {
                    possibleCm = parseInt(newValue);
                    if (isNaN(possibleCm) || possibleCm > 300) {
                        console.info("Bad cm", possibleCm);
                        possibleCm = '';
                    }

                }
                setSession({
                    physical_cm: possibleCm
                });
                break;
            default:
                break;
        }

    }


    return (

        <Container fluid>
            <Row>
                <Col className="col-md-12 py-0 px-0">
                    <InputGroup>
                        <InputGroup.Text>cm</InputGroup.Text>
                        <Form.Control onChange={onChange} value={session.physical_cm || ""} id="physical_cm" title="Height (cm)" className={hasErrors ? "is-invalid" : ""} type="text" placeholder="cm" />
                    </InputGroup>
                </Col>
            </Row>
        </Container>

    );

}


export const WeightControl = ({ hasErrors }) => {

    const { session, setSession } = useContext(SessionContext);

    const [buttonLabel, setButtonLabel] = useState("kg");

    useEffect(() => {

        if (undefined !== session.physical_units_weight) {

            if ("metric" === session.physical_units_weight) {
                if (undefined !== session.physical_kg) {
                    let kg = parseInt(session.physical_kg);
                    if (!isNaN(kg) && kg >= 10 && kg <= 500) {
                        let lbs = toLbs({ kg: kg });

                        setSession({
                            physical_kg: kg,
                            physical_lbs: lbs,
                            physical_weight: kg + " kg"
                        });
                    } else {
                        setSession({
                            physical_lbs: '',
                            physical_weight: ''
                        });
                    }
                }
                setButtonLabel("lbs");
            } else {
                if (undefined !== session.physical_lbs) {
                    let lbs = parseInt(session.physical_lbs);

                    if (!isNaN(lbs) && lbs >= 10 && lbs <= 900) {
                        let kg = toKg({ lbs: lbs });

                        setSession({
                            physical_kg: kg,
                            physical_lbs: lbs,
                            physical_weight: lbs + " lbs"
                        });
                    } else {
                        setSession({
                            physical_kg: '',
                            physical_weight: ''
                        });
                    }
                }
                setButtonLabel("kg");
            }

        } else {
            setSession({
                physical_units_weight: "imperial"
            });
        }


    }, [session.physical_units_weight, session.physical_lbs, session.physical_kg]);

    const toggleUnits = () => {
        let newValue;
        if ("metric" === session.physical_units_weight) {
            newValue = "imperial";
        } else {
            newValue = "metric";
        }
        setSession({
            physical_units_weight: newValue
        });

    }

    return (
        <Container fluid>
            <Row>
                <Col className="py-0 px-0">
                    <Form.Label>What is your weight?</Form.Label>
                </Col>
            </Row>
            <Row className='mb-2'>
                <Col className="col-md-9 py-0 px-0">
                    {"metric" === session.physical_units_weight ?
                        <MetricWeightControl hasErrors={hasErrors} />
                        :
                        <ImperialWeightControl hasErrors={hasErrors} />
                    }
                </Col>
                <Col className="col-md-3 py-0 px-1 text-right text-end">
                    <Button title={"Switch to " + buttonLabel} variant="outline-info" onClick={toggleUnits}>
                        <BsArrowLeftRight /> {buttonLabel}
                    </Button>
                </Col>
            </Row>
        </Container>
    )

}

export const ImperialWeightControl = ({ hasErrors }) => {


    const { session, setSession } = useContext(SessionContext);


    const onChange = (e) => {

        let newValue = e.target.value.replace(/\D/g, '');

        switch (e.target.id) {
            case 'physical_lbs':
                let possibleLbs = '';
                if ('' !== newValue) {
                    possibleLbs = parseInt(newValue);
                    if (isNaN(possibleLbs) || possibleLbs > 900) {
                        possibleLbs = '';
                    }

                }
                setSession({
                    physical_lbs: possibleLbs
                });
                break;
            default:
                break;
        }

    }


    return (

        <Container fluid>
            <Row>
                <Col className="col-md-12 py-0 px-0">
                    <InputGroup>
                        <InputGroup.Text>lbs</InputGroup.Text>
                        <Form.Control onChange={onChange} value={session.physical_lbs || ""} id="physical_lbs" title="Weight (lbs)" className={hasErrors ? "is-invalid" : ""} type="text" placeholder="lbs" />
                    </InputGroup>
                </Col>
            </Row>
        </Container>

    );

}


export const MetricWeightControl = ({ hasErrors }) => {


    const { session, setSession } = useContext(SessionContext);


    const onChange = (e) => {

        let newValue = e.target.value.replace(/\D/g, '');

        switch (e.target.id) {
            case 'physical_kg':
                let possibleKg = '';
                if ('' !== newValue) {
                    possibleKg = parseInt(newValue);
                    if (isNaN(possibleKg) || possibleKg > 500) {
                        possibleKg = '';
                    }

                }
                setSession({
                    physical_kg: possibleKg
                });
                break;
            default:
                break;
        }

    }


    return (

        <Container fluid>
            <Row>
                <Col className="col-md-12 py-0 px-0">
                    <InputGroup>
                        <InputGroup.Text>kg</InputGroup.Text>
                        <Form.Control onChange={onChange} value={session.physical_kg || ""} id="physical_kg" title="Weight (kg)" className={hasErrors ? "is-invalid" : ""} type="text" placeholder="kg" />
                    </InputGroup>
                </Col>
            </Row>
        </Container>

    );

}

export const ImperialHeight = ({ hasErrors }) => {
    const { session, setSession } = useContext(SessionContext);

    useEffect(() => {

        let validSize = false;
        if (undefined !== session.physical_feet && undefined !== session.physical_inches) {
            if ("" !== session.physical_feet && "" !== session.physical_inches) {
                if (parseInt(session.physical_feet) > 0 && parseInt(session.physical_feet) < 9) {
                    if (parseInt(session.physical_inches) >= 0 && parseInt(session.physical_inches) < 12) {
                        setSession({
                            physical_height: session.physical_feet.toString() + "'" + session.physical_inches.toString()
                        });
                        validSize = true;
                    }
                }
            }
        }
        if (!validSize) {
            setSession({
                physical_height: ""
            });
        }

    }, [session.physical_feet, session.physical_inches]);

    let feetItems = [4, 5, 6, 7].map((item) =>
        <option value={item} key={item}>{item} ft</option>
    );

    let inchItems = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((item) =>
        <option value={item} key={item}>{item} in</option>
    );

    const onValueChange = (e) => {

        setSession({
            [e.target.id]: e.target.value
        });

    }

    const toMetric = function () {
        return toCm({ feet: session.physical_feet, inches: session.physical_inches });
    }

    const toggleUnits = (syncOnly) => {


        let newSession = {
            physical_cm: toMetric()
        };
        newSession.physical_height = newSession.physical_cm + " cm";

        if (undefined === syncOnly) {
            newSession.physical_units_height = "metric";
        }
        setSession(newSession);
    }

    return (

        <Container fluid>
            <Row>
                <Col className='col-4'>
                    <Form.Select className={hasErrors ? "is-invalid" : ""} aria-label="feet" id="feet" value={session.physical_feet} onChange={(e) => onValueChange(e)}>
                        <option disabled value="0" key="0">Choose:</option>
                        {feetItems}
                    </Form.Select>
                </Col>
                <Col className='col-4'>
                    <Form.Select className={hasErrors ? "is-invalid" : ""} aria-label="inches" id="inches" value={session.physical_inches} onChange={(e) => onValueChange(e)}>
                        {inchItems}
                    </Form.Select>
                </Col>
                <Col className='col-3'>
                    <Badge id="unit-toggle-cm" className="clicker" onClick={() => toggleUnits()}><BsArrowLeftRight /> cm</Badge>
                </Col>
            </Row>
        </Container>

    )

}


export const MetricHeight = ({ hasErrors }) => {

    const { session, setSession } = useContext(SessionContext);

    const onValueChange = (e) => {

        let newValue = e.target.value.replace(/\D/g, '');

        if (isNaN(parseInt(newValue))) {
            //do nothing instead?
            newValue = "";
        } else {
            if (parseInt(newValue) > 225) {
                newValue = "0";
            } else {
                newValue = parseInt(newValue).toString().substring(0, 3);
            }
        }

        let newSession = {
            physical_cm: newValue
        };
        if (newValue) {
            newSession.physical_height = newValue + " cm";
        }

        // we want to save the alternate unit right away
        let imperialValue = fromMetric(newValue);
        newSession.physical_feet = imperialValue.feet;
        newSession.physical_inches = imperialValue.inches;
        setSession(newSession);

    }

    const fromMetric = function (cm) {
        return toFeet({ cm: cm });
    }

    const toggleUnits = (syncOnly) => {
        let imperial = fromMetric(session.physical_cm);
        let newSession = {
            physical_feet: imperial.feet,
            physical_inches: imperial.inches
        };
        if (undefined === syncOnly) {
            newSession.physical_units_height = "imperial";
        }
        setSession(newSession);
    }

    return (
        <Container fluid>
            <Row>
                <Col className='col-8'>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1">cm</InputGroup.Text>
                        <Form.Control className={hasErrors ? "is-invalid" : ""} type="text" placeholder="Enter height in cm" value={session.physical_cm} onChange={e => onValueChange(e)} />
                    </InputGroup>


                </Col>
                <Col>
                    <Badge id="unit-toggle-ft" className="clicker" onClick={() => toggleUnits()}><BsArrowLeftRight /> ft</Badge>
                </Col>
            </Row>
        </Container>
    )

}



export const ImperialWeight = ({ hasErrors }) => {

    const { session, setSession } = useContext(SessionContext);

    const onValueChange = (e) => {

        let newValue = e.target.value.replace(/\D/g, '');

        if (isNaN(parseInt(newValue))) {
            //do nothing instead?
            newValue = "";
        } else {
            if (parseInt(newValue) > 999) {
                newValue = "1";
            } else {
                newValue = parseInt(newValue).toString().substring(0, 3);
            }
        }

        let newSession = {
            physical_lbs: newValue
        };
        if (newValue) {
            newSession.physical_weight = newValue + " lbs";
        }

        // we want to save the alternate unit right away
        newSession.physical_kg = fromImperial();

        setSession(newSession);



    }

    const fromImperial = function () {
        return toKg({ lbs: lbs });
    }

    const toggleUnits = (syncOnly) => {
        let newSession = {
            physical_kg: fromImperial()
        };
        newSession.physical_weight = newSession.physical_kg + " kg";

        if (undefined === syncOnly) {
            newSession.physical_units_weight = "metric";
        }
        setSession(newSession);
    }

    return (
        <Container fluid>
            <Row>
                <Col className='col-8'>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1">lbs</InputGroup.Text>
                        <Form.Control className={hasErrors ? "is-invalid" : ""} type="text" placeholder="Enter weight in lbs" value={session.physical_lbs} onChange={e => onValueChange(e)} />
                    </InputGroup>
                </Col>
                <Col>
                    <Badge id="unit-toggle-kg" className="clicker" onClick={() => toggleUnits()}><BsArrowLeftRight /> kg</Badge>
                </Col>
            </Row>
        </Container>
    )

}


export const MetricWeight = ({ hasErrors }) => {
    const { session, setSession } = useContext(SessionContext);
    const onValueChange = (e) => {

        let newValue = e.target.value.replace(/\D/g, '');

        if (isNaN(parseInt(newValue))) {
            //do nothing instead?
            newValue = "";
        } else {
            if (parseInt(newValue) > 999) {
                newValue = "1";
            } else {
                newValue = parseInt(newValue).toString().substring(0, 3);
            }
        }

        let newSession = {
            physical_kg: newValue
        };
        if (newValue) {
            newSession.physical_weight = newValue + " kg";
        }

        // we want to save the alternate unit right away
        newSession.physical_lbs = fromMetric();
        setSession(newSession);

    }

    const fromMetric = function () {
        return toLbs({ kg: kg });
    }

    const toggleUnits = (syncOnly) => {
        let newSession = {
            physical_lbs: fromMetric()
        };
        newSession.physical_weight = newSession.physical_lbs + " lbs";

        if (undefined === syncOnly) {
            newSession.physical_units_weight = "imperial";
        }
        setSession(newSession);
    }

    return (
        <Container fluid>
            <Row>
                <Col className='col-8'>
                    <InputGroup className="mb-3">
                        <InputGroup.Text id="basic-addon1">kg</InputGroup.Text>
                        <Form.Control className={hasErrors ? "is-invalid" : ""} type="text" placeholder="Enter weight in kg" value={session.physical_kg} onChange={e => onValueChange(e)} />
                    </InputGroup>
                </Col>
                <Col>
                    <Badge id="unit-toggle-lb" className="clicker" onClick={() => toggleUnits()}><BsArrowLeftRight /> lbs</Badge>
                </Col>
            </Row>
        </Container>

    )

}


