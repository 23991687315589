import {
    useContext,
    useEffect,
    useReducer,
    useState,
} from 'react';

import {
    Button,
    Card,
    Col,
    Container,
    Form,
    Row,
} from 'react-bootstrap';

import { PreviousNextButtons } from '../controls/buttons';
import { RadioControl } from '../controls/radio';
import { TextControl } from '../controls/text';
import {
    asPhoneNumber,
    isEmail,
    isPhoneNumber,
} from '../libs/contact';
import {
    BrandingContext,
    GeoContext,
    InsuranceDataContext,
    LayoutContext,
    NotificationsContext,
    SessionContext,
    stateReducer,
} from '../libs/contexts';
import { scrollUp } from '../libs/ui';

export const Beneficiary = () => {

    const { branding, setBranding } = useContext(BrandingContext);
    const { layout, setLayout } = useContext(LayoutContext);
    const { session, setSession } = useContext(SessionContext);

    const [updated, setUpdated] = useState(false);


    const validateScreen = ({ forceValidation }) => {



        if (forceValidation) {
            setLayout({
                lastValidated: "beneficiary"
            });
            setSession({ validationAttempted: Date.now() });
        }


    }

    useEffect(() => {

        scrollUp();
        setLayout({
            screenReady: false
        });

    }, []);

    useEffect(() => {

        if (updated && undefined !== layout.fields) {


            if (layout.fields && undefined === layout.fields[updated]) {
                let newFields = { ...layout.fields };
                newFields[updated] = "ok";
                setLayout({
                    fields: newFields
                });
            }

        }

    }, [updated, layout.fields]);

    const onChange = ({ id, value }) => {

        console.info(id, value);
        setUpdated(id);



    }

    return (
        <>
            <Container>
                <Row className="mt-4 mb-4">
                    <Col>
                        <h3>Beneficiary</h3>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col className="col-lg-12 col-xl-10">
                        <TextControl label="Name of your beneficiary" title="Please provide us with the name of your beneficiary" field="beneficiary_name" placeholder="Name" onChange={onChange} required={true} type="text" />
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col className="col-lg-12 col-xl-10">
                        <TextControl label="Relationship" title="Please provide us with your relation with the beneficiary" field="beneficiary_relationship" placeholder="Relationship" onChange={onChange} required={true} type="text" />
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col>
                        <RadioControl label="Is your beneficiary a minor?" type="yesno" helpMessage="Answer yes if your beneficiary is currently under 18 years old." onChange={onChange} field="beneficiary_minor" />
                    </Col>
                </Row>
                {"Yes" === session.beneficiary_minor &&
                    <Row className="mt-2">
                        <Col className="col-lg-12 col-xl-10">
                            <TextControl label="Trustee" title="The trustee of your beneficiary" field="beneficiary_trustee" placeholder="Trustee" onChange={onChange} required={true} type="text" />
                        </Col>
                    </Row>
                }

                <Row className="mt-2">
                    <Col>
                        <RadioControl label="Do you want to specify a contingent beneficiary?" type="yesno" onChange={onChange} field="beneficiary_contingent" />
                    </Col>
                </Row>
                {"Yes" === session.beneficiary_contingent &&
                    <>
                        <Row className="mt-2">
                            <Col className="col-lg-12 col-xl-10">
                                <TextControl label="Contingent beneficiary" title="The name of the contingent beneficiary" field="beneficiary_contingent_name" placeholder="Contingent name" onChange={onChange} required={true} type="text" />
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col className="col-lg-12 col-xl-10">
                                <TextControl label="Relationship with contingent beneficiary" title="Relationship with the contingent beneficiary" field="beneficiary_contingent_relationship" placeholder="Relationship" onChange={onChange} required={true} type="text" />
                            </Col>
                        </Row>
                    </>
                }
                <Row className="mt-4">
                    <Col>
                        {layout &&
                            <PreviousNextButtons enabled={layout.screenReady} callback={validateScreen} />
                        }
                    </Col>
                </Row>
            </Container>
        </>
    )

}
