import * as React from 'react';

import * as ReactDOM from 'react-dom/client';

import { LinkShopper } from './app';

let params = new URLSearchParams(window.location.search);
let entries = params.entries();
let query = Object.fromEntries(entries);

ReactDOM.createRoot(document.getElementById("app")).render(
    <LinkShopper query={query} />
);
