import {
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';

import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
} from 'react-bootstrap';
import {
  useLocation,
  useNavigate,
} from 'react-router-dom';

import { RadioControl } from '../controls/radio';
import { TextControl } from '../controls/text';
import { SimpleCarousel } from '../controls/wizards';
import {
  asPhoneNumber,
  isEmail,
  isPhoneNumber,
} from '../libs/contact';
import {
  BrandingContext,
  GeoContext,
  InsuranceDataContext,
  LayoutContext,
  NotificationsContext,
  SessionContext,
  stateReducer,
} from '../libs/contexts';
import { scrollUp } from '../libs/ui';
import { extractQuestions } from '../libs/workflow';

export const Medical = () => {

    const { branding, setBranding } = useContext(BrandingContext);
    const { layout, setLayout } = useContext(LayoutContext);
    const { session, setSession } = useContext(SessionContext);
    const { insuranceData } = useContext(InsuranceDataContext);
    const [questions, setQuestions] = useState(false);

    let navigate = useNavigate();
    let location = useLocation();
    const [fields, setFields] = useReducer(stateReducer, {
        first_name: false,
        last_name: false,
        phone: false,
        email: false,
        privacy: false
    });

    //const [mustRevalidate, setMustRevalidate] = useState(false);

    useEffect(() => {

        if (insuranceData.bundled) {
            let newQuestions = extractQuestions(insuranceData.bundled["diagnostic"], "carousel");
            setQuestions(newQuestions);
        }

    }, [insuranceData.bundled]);

    useEffect(() => {
        scrollUp();
        let requirements = { ...layout.requirements };
        //0 === failed ? "pass" : "fail";
        requirements.medical = "pass"; //0 === failed ? "pass" : "fail";

        let newLayout = {
            requirements: requirements
        };

        setLayout(newLayout);

    }, []);

    // useEffect(() => {

    //     const forceValidation = () => {
    //         setMustRevalidate(true);
    //     }

    //     let newLayout = {
    //         errorCallback: forceValidation
    //     }

    //     setLayout(newLayout);

    // }, [mustRevalidate]);


    const onChange = ({ id, value }) => {

        if ("phone" === id) {
            if (value && value.length > 3) {
                value = asPhoneNumber({ contactInfo: value });
                setSession({
                    phone: value
                });
            }
        }

        // console.log("Received change", id, isValid);
        // let newFields = {};
        // newFields[id] = isValid;
        // setFields(newFields);

        validateScreen();
    }

    const moveOn = () => {
        //
    }

    const validateScreen = () => {


        let failed = 0;
        for (let [k, v] of Object.entries(fields)) {
            if (undefined === session[k]) {
                failed++;
                //console.info("Missing field", k, v);
            }
        }

        let requirements = { ...layout.requirements };
        requirements.welcome = "pass"; //0 === failed ? "pass" : "fail";
        requirements.landing = "pass"; //0 === failed ? "pass" : "fail";

        let newLayout = {
            requirements: requirements
        };
        //console.info("Requirements", requirements);
        setLayout(newLayout);
    }

    return (
        <>
            <Container>
                <Row className="mt-4 mb-4">
                    <Col>
                        <h3>About you</h3>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col className="col-lg-12 col-xl-10">
                        {questions &&
                            <SimpleCarousel onCompleted={moveOn} questions={questions} title="Have you ever been treated for, diagnosed, consulted a doctor, received abnormal test results or experienced symptoms of the following:" />
                        }
                    </Col>
                </Row>

            </Container>
        </>
    )

}
