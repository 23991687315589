import {
  React,
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  Button,
  Card,
  Col,
  Container,
  Row,
} from 'react-bootstrap';
import { BsTelephone } from 'react-icons/bs';
import { HiOutlineMail } from 'react-icons/hi';
import {
  useLocation,
  useNavigate,
} from 'react-router-dom';

import * as config from '../config.js';
import {
  getSignatureToken,
  prepareDocuments,
  requestApproveApplication,
  requestSignatureAudit,
} from '../libs/api.js';
import {
  BrandingContext,
  GeoContext,
  InsuranceDataContext,
  LayoutContext,
  NotificationsContext,
  SessionContext,
  stateReducer,
} from '../libs/contexts';
import { asCurrency } from '../libs/i18n.js';

//asCurrency = ({ amount
export const Consent = () => {

    const [ceremonyStage, setCeremonyStage] = useState('not started');
    const { layout, setLayout } = useContext(LayoutContext);
    const { session, setSession } = useContext(SessionContext);
    const { branding } = useContext(BrandingContext);

    let navigate = useNavigate();
    //let location = useLocation();


    useEffect(() => {

    }, []);


    useEffect(() => {

        if ('not started' === ceremonyStage) {
            if (session.token) {
                console.info("About to request docs");
                setCeremonyStage('ready for templates');
            } else {
                console.info("No session token yet");
            }
        }

        if ('ready for templates' === ceremonyStage) {
            setCeremonyStage('waiting for templates')
            console.info("requesting new templates");
            prepareDocuments({
                quote: session, token: session.token, next: (data) => {
                    console.info("output from request", data);
                    setCeremonyStage('ready for tokens');
                }
            });

        }

        if ('ready for tokens' === ceremonyStage) {
            // we have the pdf in the cloud, now we get the OneSpan token
            setCeremonyStage('waiting for tokens');
            getSignatureToken({
                token: session.token, next: (data) => {
                    let newSession = { ...session };
                    //newSession.signatureToken = data.token;
                    console.log("Got token data", data);
                    newSession.signatureInfo = data.signatureInfo;
                    if (data.signatureInfo) {
                        newSession.packageId = data.signatureInfo.packageId;
                    }
                    setSession(newSession);
                    setTimeout(() => {
                        setCeremonyStage('ready for signature');
                    }, 1000)
                }
            });

        }

    }, [ceremonyStage, session.token]);




    return (
        <>
            <Container fluid>
                {'ready for signature' === ceremonyStage ?
                    <SignatureBox />
                    :
                    <AlmostThere />
                }
            </Container>

        </>
    )
}


const AlmostThere = () => {

    return (
        <Container fluid>
            <Row>
                <Col>
                    <h1 className="display-4 fw-bold lh-1 mb-3">Almost there!</h1>
                </Col>
            </Row>
            <Row className="mt-1">
                <Col className="col-12">
                    We're preparing the paperwork. In a few seconds you'll be able to review your answers and make your final decision!
                </Col>
            </Row>
            <Row className="mt-4">
                <Col className="col-12 text-center">
                    <video style={{ width: "340px" }} autoPlay muted loop>
                        <source src={config.assets + "/working.mp4"} type="video/mp4" />
                    </video>
                </Col>
            </Row>
        </Container>

    )

}


const SignatureBox = (props) => {


    const { layout, setLayout } = useContext(LayoutContext);
    const { session, setSession } = useContext(SessionContext);


    // this url will come from the OneSpan API, it is quote-specific
    const [signatureUrl, setSignatureUrl] = useState(false);

    // this helps us determine if we should show the iframe or not
    // we will increase the counter with every load event
    // once we reach 3 it means the signature is completed (onespan redirect) and the iframe can be closed
    const [iframeLoadCount, setIframeLoadCount] = useState(0);

    const [showInstructions, setShowInstructions] = useState('intro');
    //const [signed, setSigned] = useState(false);

    let navigate = useNavigate();
    let location = useLocation();


    useEffect(() => {

        let uri = location.pathname;

        if ("yes" === session.final) {
            console.info("Final step!!!");
            ////navigate("/policy");
        } else {
            console.info("Not final step");
        }

    }, []);

    useEffect(() => {

        if (signatureUrl && signatureUrl.startsWith("https")) {
            // we likely have a valid url
            setIframeLoadCount(1);
        }

    }, [signatureUrl]);

    useEffect(() => {

        if (session.signatureInfo && session.signatureInfo.url) {
            let newUrl = session.signatureInfo.url;
            setSignatureUrl(newUrl);
        }

    }, [session.signatureInfo]);

    useEffect(() => {


        const onMessageReceived = (event) => {
            //console.info("Cool, we got a message", event);

            var origin = event.origin || event.originalEvent.origin;
            var data = event.data;
            //console.log(data, origin);

            switch (data) {
                case 'ESL:MESSAGE:REGISTER':
                    event.source.postMessage('ESL:MESSAGE:ACTIVATE_EVENTS', origin);
                    break;
                case 'ESL:MESSAGE:STARTED:SIGNER_COMPLETE':
                    //console.info("READY TO MOVE ON");
                    requestSignatureAudit({
                        token: session.token, next: (data) => {
                            console.info("Audit info", data);
                        }
                    });

                    let newSession = { ...session };

                    /*
                    let flow = getNextStep(location, newSession);

                    for (let [k, v] of Object.entries(flow)) {
                        newSession[k] = v;
                    }*/

                    setSession(newSession);
                    setIframeLoadCount(3);

                    // we ask approve to process the application
                    // requestApproveApplication((data) => {

                    // });

                    // if (newSession.next) {
                    //     navigate(newSession.next);
                    // }



                    break;
                default:
                    //event.source.postMessage(data, origin)
                    break;
            }

        };

        window.addEventListener('message', onMessageReceived, false);

        return () => {
            window.removeEventListener('message', onMessageReceived);
        }



    }, []);




    const onFrameLoad = (e) => {

        // we expect two load events: for the ceremony URL, and for the redirect when the customer completes the process
        console.info(Date.now(), "iframe load", e);
        let newCount = iframeLoadCount + 1;
        setIframeLoadCount(newCount);

        if (2 === newCount) {
            setTimeout(() => {
                setShowInstructions(false);
            }, 5000);
            setTimeout(() => {
                setShowInstructions('complete');
            }, 10000);
        }

    }

    const giveUp = () => {
        saveAndContinue(false);
    }

    const moveForward = () => {
        saveAndContinue(true);
        requestSignatureAudit((data) => {
            console.info("Audit info", data);
        })
    }

    const saveAndContinue = (success) => {

        let newSession = { ...session };

        if (!success) {
            newSession.outcome = "stop";
        }

        /*
        let flow = getNextStep(location, newSession);

        for (let [k, v] of Object.entries(flow)) {
            newSession[k] = v;
        }*/

        setSession(newSession);


        console.info("Should request now");
        if (success) {
            // we ask approve to process the application
            requestApproveApplication({
                token: session.token, next: (data) => {
                    console.info("Application submitted", data);
                    setSession({ applied: "yes" });
                }
            });

            //if (newSession.next) {
            //    navigate(newSession.next);
            //}
        } else {
            //navigate(newSession.next);
        }



    }


    return (
        <Container>
            {signatureUrl && iframeLoadCount > 0 && iframeLoadCount < 3 ?
                <Row className="text-center">
                    {'intro' === showInstructions &&
                        <p>
                            Please review every page of this document and use the e-signature button to confirm.
                        </p>
                    }
                    {'complete' === showInstructions &&
                        <p>
                            When you are done with the e-signature, you can download a copy and then click the blue button to continue.
                        </p>
                    }
                    <iframe id="esignatureframe" loading="eager" title="Signature" width={"1000px"} height={"800px"} src={signatureUrl} onLoad={(e) => onFrameLoad(e)}></iframe>
                </Row>
                :
                <>
                    {iframeLoadCount < 3 &&
                        <p>Preparing e-signature...</p>
                    }
                </>
            }
            {iframeLoadCount >= 3 &&
                <>
                    <Row className="mt-4 mb-4">
                        <Col className="col-md-6 offset-md-3 text-center text-lg-start">
                            <Card>
                                <Card.Header>
                                    Your selection
                                </Card.Header>
                                <Card.Body>
                                    <Card.Title>{asCurrency(session.premium)}/mo
                                    </Card.Title>
                                    <ul>
                                        <li>{asCurrency(session.facevalue)} policy</li>
                                        <li>Price guaranteed for {session.term} years.</li>
                                        {"yes" === session.rider ?
                                            <li>Premium waiver for total disability</li>
                                            :
                                            <li>No premium waiver</li>
                                        }
                                    </ul>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="mt-4 mb-4">
                        <Col className='col-md-6 offset-md-3'>
                            <Card>
                                <Card.Header>
                                    Questions?
                                </Card.Header>
                                <Card.Body>
                                    <p><BsTelephone /> {layout.phone}</p>
                                    <p><HiOutlineMail /> {layout.email}</p>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="mt-4">

                        <Col className="col-md-12 col-lg-6 offset-lg-3 text-center">
                            <h5>Are you ready to proceed?</h5>
                        </Col>
                        <Col className="col-md-12 col-lg-6  offset-lg-3 text-center">
                            <div className="mt-1">
                                <Button onClick={() => moveForward()} variant="primary" size="lg">Yes, I want my policy</Button>{' '}
                                <Button onClick={() => giveUp()} variant="outline-info" size="lg">No, I'm not interested</Button>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-1 mb-4">
                        <Col className="col-md-6 offset-md-3 text-center">
                            <Button onClick={() => giveUp()} variant="outline-primary" className='border-0'><u>Maybe?</u></Button>
                        </Col>
                    </Row>
                </>
            }
        </Container>
    );



}

