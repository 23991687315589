

export const DummyComponent = ({ name }) => {

    return (
        <>
            <p>Component: {name}</p>
        </>
    );

}
