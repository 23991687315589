import {
    isEmail,
    isPhoneNumber,
} from './contact';

export const validateScreenFields = ({ screen, session }) => {

    let screenFields = {
        'landing': ['first_name', 'last_name', 'email', 'phone', 'privacy'],
        'welcome': ['first_name', 'last_name', 'email', 'phone', 'privacy'],
        'review': ['first_name', 'last_name', 'email', 'phone'],
        'address': ['address1', 'city', 'province_current', 'postal_code', 'province_birth'],
        'beneficiary': ['beneficiary_name', 'beneficiary_relationship', 'beneficiary_minor'],
        'quoter': ['age', 'facevalue'],
        'payment': ['payment_transit', 'payment_account', 'payment_address', 'payment_bank', 'payment_day'],
        'general': ['physical_height', 'physical_weight', 'dob', 'A4350', 'A4351']
    };

    let validFields = [];
    let invalidFields = [];

    if (screen && screenFields[screen]) {

        for (let field of screenFields[screen]) {
            if (undefined === session[field] || "" === session[field]) {
                invalidFields.push(field);
            } else {

                switch (field) {
                    case "dob":
                        if (10 === session[field].length) {
                            validFields.push(field);
                        } else {
                            invalidFields.push(field);
                        }
                        break;
                    case "email":
                        if (isEmail({ contactInfo: session[field] })) {
                            validFields.push(field);
                        } else {
                            invalidFields.push(field);
                        }
                        break;
                    case "phone":
                        if (isPhoneNumber({ contactInfo: session[field] })) {
                            validFields.push(field);
                        } else {
                            invalidFields.push(field);
                        }
                        break;
                    case "privacy":
                        if ("Yes" === session[field]) {
                            validFields.push(field);
                        } else {
                            invalidFields.push(field);
                        }
                        break;
                    default:
                        validFields.push(field);
                        break;
                }

            }

        }

    }

    return (
        { valid: validFields, invalid: invalidFields }
    );
}

export const extractQuestions = (bundle, uiType) => {

    let newQuestions = [];

    switch (uiType) {

        case "carousel":
            for (let question of bundle.questions) {
                if ("radio" === question.type) {
                    let newQuestion = {};
                    newQuestion.id = question.id;
                    newQuestion.copy = question.copy;
                    newQuestion.help = question.help;
                    for (let [choiceId, choice] of Object.entries(question.choices)) {
                        switch (choice.copy) {
                            case "No":
                                newQuestion["no"] = choiceId;
                                break;
                            case "Yes":
                                newQuestion["yes"] = choiceId;
                                break;
                            default:
                                break;
                        }
                    }
                    if (undefined !== question.bullets && question.bullets.length > 0) {
                        newQuestion.bullets = question.bullets;
                    }
                    newQuestions.push(newQuestion);
                }

            }

            break;
        case "sorted":
            for (let question of bundle.questions) {
                if ("radio" === question.type) {
                    let newQuestion = {};
                    newQuestion.id = question.id;
                    newQuestion.copy = question.copy;
                    newQuestion.order = question.order;
                    newQuestion.help = question.help;
                    newQuestion.choices = new Map();
                    let unsortedChoices = [];
                    for (let [choiceId, choice] of Object.entries(question.choices)) {

                        unsortedChoices.push(choice);

                        switch (choice.copy) {
                            case "No":
                                newQuestion["no"] = choiceId;
                                break;
                            case "Yes":
                                newQuestion["yes"] = choiceId;
                                break;
                            default:
                                break;
                        }
                    }
                    if (undefined !== question.bullets && question.bullets.length > 0) {
                        newQuestion.bullets = question.bullets;
                    }

                    // we sort the choices by id
                    unsortedChoices.sort((a, b) => a.order - b.order);
                    for (let item of unsortedChoices) {
                        newQuestion.choices.set(item.id, item);
                    }

                    newQuestions.push(newQuestion);
                }
            }
            newQuestions.sort((a, b) => a.order - b.order);

            break;
        case "toggle":
            for (let question of bundle.questions) {
                if ("radio" === question.type) {
                    let newQuestion = {};
                    newQuestion.id = question.id;
                    newQuestion.copy = question.copy;
                    newQuestion.help = question.help;
                    for (let [choiceId, choice] of Object.entries(question.choices)) {
                        switch (choice.copy) {
                            case "No":
                                newQuestion["no"] = choiceId;
                                break;
                            case "Yes":
                                newQuestion["yes"] = choiceId;
                                break;
                            default:
                                break;
                        }
                    }
                    if (undefined !== question.drilldown && question.drilldown.length > 0) {
                        newQuestion.drilldown = question.drilldown;
                    }
                    if (undefined !== question.followUp && question.followUp.length > 0) {
                        newQuestion.followUp = question.followUp;
                    }
                    newQuestions.push(newQuestion);
                }
            }
            break;
        default:
            break;

    }

    return newQuestions;

}
