import {
    useContext,
    useEffect,
    useReducer,
    useState,
} from 'react';

import {
    Button,
    Card,
    Col,
    Container,
    Dropdown,
    Form,
    Image,
    Row,
} from 'react-bootstrap';
import {
    BsChevronDown,
    BsChevronRight,
    BsChevronUp,
    BsTelephone,
} from 'react-icons/bs';
import { FaRegWindowClose } from 'react-icons/fa';
import { HiOutlineMail } from 'react-icons/hi';
import { MdOutlineLiveHelp } from 'react-icons/md';
import {
    Link,
    Outlet,
    useNavigate,
} from 'react-router-dom';

import * as config from '../config.js';
import {
    BrandingContext,
    GeoContext,
    InsuranceDataContext,
    LayoutContext,
    NotificationsContext,
    SessionContext,
} from '../libs/contexts';
import { calculateScenarios } from '../libs/insurance.js';
import {
    BaseDesktopFaq,
    BaseDesktopFooter,
    BaseDesktopHeader,
    BaseDesktopMenu,
    BaseDesktopRibbon,
} from './base';

export const HeroLayout = ({ MyOutlet }) => {

    const { layout } = useContext(LayoutContext);


    return (

        <>
            <HeroDesktopMenu />
            <Container fluid>
                <Row>
                    <Col className="bg-white desktop-area">
                        <Container fluid>
                            <Row>
                                <Col className="col-md-7 offset-lg-2 col-lg-6 offset-xl-3 col-xl-5">
                                    <Container fluid>
                                        <Row>
                                            <Col>
                                                <HeroDesktopHeader />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {"yes" === layout.faq ?
                                                    <BaseDesktopFaq />
                                                    :
                                                    <>
                                                        {"jump" === layout.specialView ?
                                                            <p>We are getting your quote ready, please hold...</p>
                                                            :
                                                            <MyOutlet></MyOutlet>
                                                        }</>

                                                }

                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                {"yes" !== layout.faq &&
                                                    <HeroDesktopRibbon />
                                                }
                                            </Col>
                                        </Row>
                                    </Container>
                                </Col>
                                <Col className="col-lg-4 col-md-5 col-xl-3">
                                    {"jump" !== layout.specialView && "end" !== layout.currentScreen &&
                                        <HeroSidebar />
                                    }
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
            {"end" !== layout.currentScreen &&
                <HeroDesktopFooter />
            }

        </>
    )


}

export const HeroSidebar = () => {

    const { branding } = useContext(BrandingContext);
    const { layout, setLayout } = useContext(LayoutContext);
    const { session, setSession } = useContext(SessionContext);
    const [optionsHaveChanged, setOptionsHaveChanged] = useState(false);

    const [hasPremium, setHasPremium] = useState(false);
    const [hasOptions, setHasOptions] = useState(false);

    const [helperView, setHelperView] = useState('buttons');

    const navigate = useNavigate();


    useEffect(() => {

        if ("no" === layout.faq) {
            if (!["phone", "email"].includes(helperView)) {
                setHelperView('buttons');
            }
        }

    }, [layout.faq, helperView]);

    useEffect(() => {

        if (optionsHaveChanged) {
            let premiumOptions = calculateScenarios({ currentTerm: session.term_selected, rider: session.rider, offers: session.offers, facevalue: session.facevalue });
            setSession(premiumOptions);
            setOptionsHaveChanged(false);
        }

    }, [optionsHaveChanged, session.term_selected, session.rider, session.offers, session.facevalue]);

    useEffect(() => {


        if (undefined !== session.premium && null !== session.premium && undefined !== session.term && null !== session.term) {

            if ("needs" !== layout.quoterView) {
                setHasPremium(true);

            } else {
                setHasPremium(false);
            }

        } else {
            setHasPremium(false);
            setHasOptions(false)
        }

    }, [session.premium, session.term, layout.quoterView]);

    useEffect(() => {

        if (layout.currentScreen) {

            if (["welcome", "quoter", "landing"].includes(layout.currentScreen)) {

                setHasOptions(false);


            } else {
                if ("needs" === layout.quoterView) {
                    setHasOptions(false);
                } else {
                    setHasOptions(true);
                }
            }

        }
    }, [layout.currentScreen, layout.quoterView]);


    const toggleHelper = (button) => {

        if (button === helperView) {
            setHelperView('buttons');
            if ("faq" === button) {
                setLayout({
                    faq: "no"
                })
            }
        } else {
            setHelperView(button);
            if ("faq" === button) {
                setLayout({
                    faq: "yes"
                })
            }
        }



    }

    const toggleRider = () => {

        let newSession = {
            rider: "Yes" === session.rider ? "No" : "Yes"
        };
        setSession(newSession);
        setOptionsHaveChanged(true);


    }


    const toggleTerm = () => {

        let newSession = {
            term_selected: "term10" === session.term_selected ? "term20" : "term10"
        };
        setSession(newSession);
        setOptionsHaveChanged(true);

    }

    const reviewContactInfo = () => {

        setLayout({
            currentScreen: 'welcome'
        });
        navigate('/welcome');

    }

    return (
        <>
            <Container className="mt-4" fluid>
                {hasPremium &&
                    <Row className='mt-1'>
                        <Col>
                            <Card>
                                <Card.Body>
                                    <Card.Title><h3>{session.premium} <small>per month</small></h3></Card.Title>
                                    <Card.Text>
                                        This price is guaranteed for {session.term} years. Cancel anytime.
                                    </Card.Text>

                                    {hasOptions &&
                                        <Dropdown>
                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                Adjust your policy
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={toggleRider} >{"Yes" === session.rider ?
                                                    <>Remove</>
                                                    :
                                                    <>Add</>
                                                } total disability waiver ({session.premium_rider})</Dropdown.Item>
                                                <Dropdown.Item onClick={toggleTerm}>Switch to a {session.alternative_years} years price guarantee ({session.alternative_total})</Dropdown.Item>
                                                {/* <Dropdown.Item as={Link} to="/quoter">Change your coverage</Dropdown.Item>                                                
                                                <Dropdown.Item onClick={reviewContactInfo}>Review your contact information</Dropdown.Item> */}
                                                {/* <Dropdown.Item>Change your coverage</Dropdown.Item> */}
                                                {/* <Dropdown.Item>Review your contact information</Dropdown.Item> */}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    }

                                </Card.Body>
                                <Card.Footer>
                                    This is a <b>{session.premium_coverage}</b> policy
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                }
                <Row className="mt-4">
                    <Col>

                        <Card className="mt-4">
                            {branding.introduction &&
                                <div className="d-none d-lg-block d-xl-block d-xxl-block mx-auto">
                                    <div className="videoWrapper">
                                        <iframe className='brokerVideo' src={"https://www.youtube.com/embed/" + branding.introduction} title="YouTube video player" frameBorder="0" allowFullScreen></iframe>
                                    </div>
                                </div>
                            }
                            <Card.Body className="px-0">
                                <Container fluid>
                                    <Row className='mb-2'>
                                        <Col className="text-center">
                                            {session.first_name ?
                                                <>
                                                    Any questions, {session.first_name} ?
                                                </>
                                                : <>
                                                    Questions?
                                                </>
                                            }

                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col className='px-0'>
                                            <Button onClick={() => toggleHelper('phone')} className='full-square' variant={"phone" === helperView ? "outline-primary" : "outline-info"}>
                                                <BsTelephone />
                                                <small className="float-right float-end">

                                                    {"phone" === helperView ?
                                                        <BsChevronUp />
                                                        :
                                                        <BsChevronDown />
                                                    }

                                                </small>
                                            </Button>
                                        </Col>
                                        <Col className='px-0'>
                                            <Button onClick={() => toggleHelper('email')} className='full-square' variant={"email" === helperView ? "outline-primary" : "outline-info"}>
                                                <HiOutlineMail />
                                                <small className="float-right float-end">
                                                    {"email" === helperView ?
                                                        <BsChevronUp />
                                                        :
                                                        <BsChevronDown />
                                                    }
                                                </small>
                                            </Button>
                                        </Col>
                                        <Col className='px-0'>
                                            <Button onClick={() => toggleHelper('faq')} className='full-square' variant="outline-info">
                                                FAQ
                                                <small className="float-right float-end">
                                                    {"faq" === helperView ?
                                                        <FaRegWindowClose />
                                                        :
                                                        <></>
                                                    }
                                                </small>
                                            </Button>
                                        </Col>

                                    </Row>
                                    {"phone" === helperView &&
                                        <Row className='mt-2 text-center'>
                                            <Col>{layout.phone}</Col>
                                        </Row>
                                    }

                                    {"email" === helperView &&
                                        <Row className='mt-2 text-center'>
                                            <Col>{layout.email}</Col>
                                        </Row>
                                    }

                                </Container>
                            </Card.Body>
                        </Card>

                    </Col>
                </Row>
                <Row>
                    <Col className="text-center">
                        <Image src={config.assets + "/securepage.png"} style={{ height: "" }} />
                    </Col>
                </Row>
            </Container>
        </>
    )

}

export const HeroDesktopRibbon = () => {


    return (
        <>
            <BaseDesktopRibbon />
        </>
    )
}

export const HeroDesktopFooter = () => {

    return (
        <>
            <BaseDesktopFooter />
        </>
    );
}

export const HeroDesktopHeader = () => {

    return (
        <>
            <BaseDesktopHeader />
            {/* <h1>QuickApply <small>hero layout</small></h1> */}
        </>
    )

}

export const HeroDesktopMenu = () => {
    return (
        <BaseDesktopMenu />
    )
}

export const HeroMobileFooter = () => {
    return (
        <>
            <p>&copy; Wawanesa</p>
        </>
    )
}

export const HeroMobileHeader = () => {

    return (
        <></>
    )

}
