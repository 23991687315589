import {
    useContext,
    useEffect,
    useReducer,
    useState,
} from 'react';

import {
    Accordion,
    Button,
    Card,
    Col,
    Container,
    Form,
    Row,
} from 'react-bootstrap';

import { PreviousNextButtons } from '../controls/buttons';
import { RadioControl } from '../controls/radio';
import { TextControl } from '../controls/text';
import {
    AccordionBasic,
    SimpleCarousel,
    ToggleAccordion,
} from '../controls/wizards';
import {
    asPhoneNumber,
    isEmail,
    isPhoneNumber,
} from '../libs/contact';
import {
    BrandingContext,
    GeoContext,
    InsuranceDataContext,
    LayoutContext,
    NotificationsContext,
    SessionContext,
    stateReducer,
} from '../libs/contexts';
import { scrollUp } from '../libs/ui';
import { extractQuestions } from '../libs/workflow';

export const Last10 = () => {

    const { branding, setBranding } = useContext(BrandingContext);
    const { layout, setLayout } = useContext(LayoutContext);
    const { session, setSession } = useContext(SessionContext);
    const { insuranceData } = useContext(InsuranceDataContext);
    const [parentQuestion, setParentQuestion] = useState(false);
    const [occupations, setOccupations] = useState(false);
    const [outcome, setOutcome] = useState("incomplete");

    const [questions, setQuestions] = useState([]);
    const [heading, setHeading] = useState("");
    const [helpVisible, setHelpVisible] = useState(false);


    const [clicked, setClicked] = useState(0);


    const [viewMode, setViewMode] = useState("accordion");

    const [ready, setReady] = useState(false);

    const [completed, setCompleted] = useState(false);


    const [updated, setUpdated] = useState(false);


    const validateScreen = ({ forceValidation }) => {



        if (forceValidation) {
            setLayout({
                lastValidated: "welcome"
            });
            setSession({ validationAttempted: Date.now() });
        }


    }

    useEffect(() => {

        scrollUp();
        setLayout({
            screenReady: false
        });

    }, []);

    useEffect(() => {

        console.info("outcome", outcome);

    }, [outcome]);



    useEffect(() => {

        console.info("Questions?", questions);
        if (questions) {
            setReady(true);
        }

    }, [questions]);

    useEffect(() => {

        if (insuranceData.bundled) {
            let newQuestions = extractQuestions(insuranceData.bundled["10years"], "carousel");
            setQuestions(newQuestions);
            setReady(true);
        }

    }, [insuranceData.bundled]);

    useEffect(() => {

        if (updated && undefined !== layout.fields) {


            if (layout.fields && undefined === layout.fields[updated]) {
                let newFields = { ...layout.fields };
                newFields[updated] = "ok";
                setLayout({
                    fields: newFields
                });
            }

        }

    }, [updated, layout.fields]);

    const onChange = ({ id, value }) => {

        console.info(id, value);
        setUpdated(id);

        if ("phone" === id) {
            if (value && value.length > 3) {
                value = asPhoneNumber({ contactInfo: value });
                setSession({
                    phone: value,
                    asOf: Date.now()
                });
            }
        }

    }

    const moveForward = (e) => {

        //setCompleted(true);

    }


    return (
        <>
            <Container>
                <Row className="mt-4 mb-4">
                    <Col>
                        <h3>About you</h3>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col className="col-lg-12 col-xl-10">
                        {ready &&
                            <SimpleCarousel onCompleted={moveForward} questions={questions} title="In the last ten years, have you:" />
                        }
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col>
                        {layout && completed &&
                            <PreviousNextButtons enabled={layout.screenReady} callback={validateScreen} />
                        }
                    </Col>
                </Row>
            </Container>
        </>
    )

}
