

/**
 * @module contact
 */


export const asHyperlink = ({ contactInfo }) => {

    let hyperlink = {
        text: contactInfo,
        href: '#'
    }

    if (contactInfo) {

        if (contactInfo.includes('@')) {
            if (isEmail({ email: contactInfo })) {
                contactInfo = asEmailAddress({ contactInfo: contactInfo });
                hyperlink.text = contactInfo;
                hyperlink.href = `mailto:${contactInfo}`
            }
        } else {
            let phonenum = contactInfo.replace(/\D/g, '');
            if (phonenum.length >= 10) {
                if (phonenum.length === 10) {
                    phonenum = `1${phonenum}`;
                }
                hyperlink.text = asPhoneNumber({ contactInfo: contactInfo });
                hyperlink.href = `tel:+${phonenum}`

            }
        }

    }

    return hyperlink;

}


/**
 * Verify that the email address provided meets expected structure
 * @memberof contact
 * @param {Object} options
 * @param {string} options.email Email address
 * @param {string} options.rules Validation rules. Can be basic or advanced. Defaults to advanced.
 * @param {boolean} options.verbose Extra logging or not
 */
export const isEmail = ({ contactInfo, rules, verbose }) => {

    let email = contactInfo;

    if (!email) {
        if (verbose) {
            console.info("No value provided", email);
        }
        return false;
    }

    if (email.length < 5) {
        if (verbose) {
            console.info("Too short", email);
        }
        return false;
    }


    let [account, domain] = email.split('@');

    if (undefined === account || undefined === domain) {
        if (verbose) {
            console.info("No domain name", email);
        }
        return false;
    }

    if (account.length < 1 || account.length > 64) {
        if (verbose) {
            console.info("username too short or too long", email);
        }
        return false;
    }

    if (domain.length < 1 || domain.length > 255) {
        if (verbose) {
            console.info("domain too short or too long", email);
        }
        return false;
    }



    let domainParts = domain.split('.');

    if (domainParts.length < 2) {
        if (verbose) {
            console.info("no fqdn", email);
        }
        return false;
    }

    for (let domainPart of domainParts) {
        if (domainParts.length < 2) {
            if (verbose) {
                console.info("fqdn too short", email, domainPart);
            }
            return false;
        }

    }

    if ('basic' === rules) return true; // good enough for basic rules

    let tester = /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

    if (!tester.test(email)) {
        if (verbose) {
            console.info("regex fail", email);
        }
        return false;
    }

    return true;

}

/**
 * Extract email address from provided string, removing extra formatting
 * @memberof contact
 * @param {Object} options
 * @param {string} options.contactInformation Email address with possible extra formatting
 * @param {boolean} options.keepMailto Optional. Keep "mailto:" if present in the contact info. Defaults to false.
 * @param {boolean} options.keepDisplayName Optional. Keep the display name part of the email address if present in the contact info. ex: "John Doe" <...>. Defaults to false.
 * @returns {string} email address
 */
export const asEmailAddress = ({ contactInfo, keepMailto, keepDisplayName }) => {

    let emailAddress = contactInfo;

    if (contactInfo) {
        if (undefined === keepMailto || !keepMailto) {
            emailAddress = emailAddress.replace('mailto:', '');
        }
        if (undefined === keepDisplayName || !keepDisplayName) {
            if (emailAddress.includes('<') && emailAddress.includes('>')) {
                emailAddress = emailAddress.split('<').pop().split('>').shift();
            }
        }
    }

    return emailAddress;

}

/**
 * Returns phone number with proper formatting
 * @memberof contact
 * @param {Object} options
 * @param {string} options.contactInformation Phone number
 * @param {string} options.verbose Extra logging or not
 * @returns {string} phone number
 */
export const asPhoneNumber = ({ contactInfo, verbose }) => {

    let phoneNumber;
    let phoneDigits = contactInfo.replace(/\D/g, '');

    if (verbose) {
        console.info("Phone digits", phoneDigits, phoneDigits.length);
    }

    switch (phoneDigits.length) {
        case 7:
            phoneNumber = [phoneDigits.substring(0, 3), phoneDigits.substring(3)].join('-');
            break;
        case 10:

            if (verbose) {
                console.info("Found 10 digits", phoneDigits);
            }
            phoneNumber = "(" + phoneDigits.substring(0, 3) + ") " + [phoneDigits.substring(3, 6), phoneDigits.substring(6)].join('-');
            break;
        case 11:
            phoneNumber = "1 (" + phoneDigits.substring(1, 4) + ") " + [phoneDigits.substring(4, 7), phoneDigits.substring(7)].join('-');
            break;
        default:
            // leave it alone

            if (verbose) {
                console.info("No match based on length", phoneDigits, phoneDigits.length);
            }
            phoneNumber = contactInfo;
            break;
    }


    return phoneNumber;

}

export const isPhoneNumber = ({ contactInfo, verbose }) => {

    if (!contactInfo) {
        if (verbose) {
            console.info("Missing value", contactInfo);
        }
        return false;
    }

    if (contactInfo.length < 10) {
        if (verbose) {
            console.info("Raw value too short", contactInfo.length, contactInfo);
        }
        return false;
    }

    let phoneDigits = contactInfo.replace(/\D/g, '');

    if (phoneDigits.length < 10) {
        if (verbose) {
            console.info("Not enough digits", phoneDigits);
        }
        return false;
    }

    if (phoneDigits.length === 10) {
        if (phoneDigits.startsWith("1")) {
            if (verbose) {
                console.info("10-digits starts with 1", phoneDigits);
            }
            return false;
        }

    }

    return true;

}