import React, {
    useEffect,
    useReducer,
    useState,
} from 'react';

import {
    BrowserRouter,
    Route,
    Routes,
} from 'react-router-dom';

import { DefaultLanding } from './components/landing';
import * as screens from './components/main.jsx';
import * as config from './config';
import { BaseLayout } from './layouts/base';
import {
    continueSession,
    createSession,
    getGeoLocation,
    getReferenceData,
    saveSession,
} from './libs/api';
import { getBranding } from './libs/branding';
import {
    asEmailAddress,
    asHyperlink,
    asPhoneNumber,
} from './libs/contact.js';
import {
    BrandingContext,
    GeoContext,
    InsuranceDataContext,
    LayoutContext,
    NotificationsContext,
    SessionContext,
    stateReducer,
} from './libs/contexts';
import { getInitialQuote } from './libs/insurance';
import {
    getInitialLayoutInfo,
    getPages,
    loadFavicon,
    loadStylesheet,
    preloadStylesheet,
} from './libs/ui';

export const LinkShopper = ({ query }) => {

    const [session, setSession] = useReducer(stateReducer, {});
    const [layout, setLayout] = useReducer(stateReducer, {});
    const [branding, setBranding] = useReducer(stateReducer, {});
    const [happyPath, setHappyPath] = useState({});

    const [sessionFlag, setSessionFlag] = useState(0);

    const [hasBaseCss, setHasBaseCss] = useState(false);
    const [hasCustomCss, setHasCustomCss] = useState(false);

    const [geo, setGeo] = useState({});
    const [insuranceData, setInsuranceData] = useState({});
    const [notifications] = useState({});
    const [initialized, setInitialized] = useReducer(stateReducer, {
        branding: false,
        css: false,
        geo: false,
        insuranceData: false,
        layout: false,
        notifications: false,
        session: false
    });


    useEffect(() => {
        // let newHappyPath = {
        //     welcome: 'quoter',
        //     quoter: 'general',
        //     general: 'consent',
        //     introduction: 'address',
        //     address: 'beneficiary',
        //     medical: 'last10',
        //     last10: 'last5',
        //     last5: 'last2',
        //     last2: 'diagnosis',
        //     diagnosis: 'occupation',
        //     occupation: 'misc',
        //     misc: 'beneficiary',
        //     beneficiary: 'payment',
        //     payment: 'consent',
        //     consent: 'policy',
        //     review: 'quoter'
        // };

        let newHappyPath = {
            welcome: 'quoter',
            quoter: 'general',
            general: 'introduction',
            introduction: 'diagnosis',
            diagnosis: 'end',
            last10: 'last5',
            last5: 'last2',
            last2: 'medical',
            medical: 'occupation',
            occupation: 'misc',
            misc: 'end',
            address: 'beneficiary',
            beneficiary: 'payment',
            payment: 'consent',
            consent: 'policy'
        };
        //['welcome', 'quoter', 'general', 'introduction', 'address', 'diagnosis', 'last10', 'last5', 'last2', 'medical', 'occupation', 'misc', 'beneficiary', 'payment', 'consent', 'policy'];
        setHappyPath(newHappyPath);

        if (undefined === geo || undefined === geo.province_code) {

            getGeoLocation({
                verbose: false, next: (data) => {

                    setGeo(data);
                    setInitialized({ geo: true });

                }
            });

        }

        if (undefined === layout || undefined === layout.name) {
            loadStylesheet({
                url: config.assets + '/bootstrap-light.min.css', next: (cssId) => {
                    setHasBaseCss(true);
                    setLayout({ cssId: cssId });
                }
            });
            preloadStylesheet({ url: config.assets + '/bootstrap-light.min.css' });
            preloadStylesheet({ url: config.assets + '/bootstrap-dark.min.css' });

            let themeUrl = config.assets + '/theme.css';
            loadStylesheet({
                url: themeUrl, next: () => {
                    setHasCustomCss(true);
                }
            });
            loadFavicon({ url: config.assets + '/favicon.ico' });

            let newLayout = getInitialLayoutInfo({ query: query });
            newLayout.happyPath = { ...happyPath };
            if ("jump" === query.entry) {
                newLayout.specialView = "jump";
            }
            newLayout.currentScreen = 'welcome';


            setLayout(newLayout);
            setInitialized({ layout: true });
        }

        if (undefined === branding || undefined === branding.ab) {

            let newBranding = getBranding({ verbose: false });

            if (newBranding) {
                setBranding(newBranding);
                setInitialized({ branding: true });
            }

        }

        if (undefined === session || undefined === session.id) {

            if ("jump" === query.entry) {

                let quote = getInitialQuote({ fromJump: true });
                quote.jump_identifier = query.token;
                quote.jump_exp = query.exp;
                quote.jump_entry = query.entry;
                console.info('we have a quote', quote);

                continueSession({
                    quote: quote,
                    verbose: false,
                    next: (sessionData) => {
                        sessionData.type = "session";
                        sessionData.jumped = "yes";
                        sessionData.term = "10";
                        //sessionData.privacy = "Yes";
                        sessionData.term_selected = "term10";
                        /*if ("1" === sessionData.smoker || 1 === sessionData.smoker) {
                            sessionData.smoking_general = "Yes";
                        } else {
                            sessionData.smoking_general = "No";
                        }*/
                        setSession(sessionData);
                        setInitialized({ session: true });
                        console.info('we have a session', sessionData);

                        getReferenceData({
                            verbose: false, token: sessionData.token, next: (data) => {
                                setInsuranceData(data);
                                setInitialized({ insuranceData: true });
                            }
                        });
                    }
                });

            } else {

                let initialQuote = getInitialQuote({ fromJump: false });

                createSession({
                    quote: initialQuote,
                    verbose: false,
                    next: (sessionData) => {
                        sessionData.type = "session";
                        /// DEBUG

                        /*
                        sessionData.first_name = "Ace";
                        sessionData.last_name = "Ventura";
                        sessionData.term = "10";
                        sessionData.email = "jlmartel@wawanesa.com";
                        sessionData.phone = "(234) 567-8989";
                        
                        sessionData.privacy = "Yes";
*/
                        //sessionData.dob = "1980-01-01";
                        //sessionData.gender = "F";

                        setSession(sessionData);
                        setInitialized({ session: true });

                        getReferenceData({
                            verbose: false, token: sessionData.token, next: (data) => {
                                setInsuranceData(data);
                                setInitialized({ insuranceData: true });
                            }
                        });


                    }
                });
            }


        }


    }, []);


    useEffect(() => {


        if (geo.province_code) {



            let newLayout = {};
            let phoneAttribute = "phone_" + geo.province_code.toLowerCase();
            let emailAttribute = "email_" + geo.province_code.toLowerCase();
            if (branding[phoneAttribute]) {
                newLayout.phone = asPhoneNumber({ contactInfo: branding[phoneAttribute] });
            } else {
                if (branding.phone) {
                    newLayout.phone = asPhoneNumber({ contactInfo: branding.phone });
                }
            }
            if (branding[emailAttribute]) {
                newLayout.email = asEmailAddress({ contactInfo: branding[emailAttribute] });
            } else {
                if (branding.email) {
                    newLayout.email = asEmailAddress({ contactInfo: branding.email });
                }
            }

            if (newLayout.email) {
                newLayout.emailHref = asHyperlink({ contactInfo: newLayout.email });
            }

            if (newLayout.phone) {
                if ("mobile" === newLayout.device) {
                    newLayout.phoneHref = asHyperlink({ contactInfo: newLayout.phone });
                }
            }

            setLayout(newLayout);
        }

    }, [geo]);


    useEffect(() => {

        if (hasBaseCss && hasCustomCss) {
            setInitialized({ css: true });
        }

    }, [hasBaseCss, hasCustomCss]);

    useEffect(() => {

        if (Object.entries(happyPath).length > 0) {

            //todo refactor

            let newLayout = {
                happyPath: { ...happyPath },
                screenSequence: ['welcome', 'quoter', 'general', 'introduction', 'address', 'diagnosis', 'last10', 'last5', 'last2', 'medical', 'occupation', 'misc', 'beneficiary', 'payment', 'consent', 'policy']
            }

            newLayout.requirements = {};

            for (let k of Object.keys(newLayout.happyPath)) {

                newLayout.requirements[k] = "fail";
            }

            setLayout(newLayout);
        }

    }, [happyPath]);

    useEffect(() => {


        if (layout.name) {
            if (session.id) {
                if (undefined === session.layout) {
                    setSession({
                        layout: layout.name
                    });
                }
                if (undefined === session.layout) {
                    setSession({
                        layout: layout.name
                    });
                }
            }
        }

        if (branding.ab) {
            if (session.id && undefined === session.ab) {
                setSession({
                    ab: branding.ab,
                    profile: branding.ab
                });
            }
        }

        if (session.id) {
            if (undefined === session.params && undefined !== layout.params) {
                let newSession = {
                    params: { ...layout.params }
                };
                for (let [k, v] of Object.entries(newSession.params)) {

                    let entries = ['campaign', 'medium', 'source'];
                    for (let entry of entries) {
                        if (k.toLowerCase() === "utm_" + entry) {
                            //@todo check in reporting and streamline so we don't use both keys
                            newSession[entry] = v;
                            newSession[k.toLowerCase()] = v;
                        }
                    }
                }
                setSession(newSession);
            }
        }


    }, [session.id, session.layout, layout.name, layout.params, branding.ab]);


    useEffect(() => {

        if (initialized.branding) {


            const syncSession = () => {

                if (session.quote) {

                    saveSession({
                        quote: session, token: session.token, next: (data) => {
                            //onsole.info("Session updated...", data);
                        }
                    });

                }

            }

            const timer = setInterval(() => syncSession(), 10000);
            return () => clearInterval(timer);
        }
    }, [initialized, session.quote, session.asOf, sessionFlag]);


    useEffect(() => {

        if (session && session.quote && geo && geo.province_code) {

            let newSession = {};
            newSession.geo = geo;
            newSession.city = geo.city;
            newSession.province = geo.province;
            newSession.province_current = geo.province_code;
            setSession(newSession);

        }
    }, [geo.province_code, session.quote]);



    return (
        <SessionContext.Provider value={{ session, setSession }}>
            <GeoContext.Provider value={{ geo }}>
                <BrandingContext.Provider value={{ branding }}>
                    <InsuranceDataContext.Provider value={{ insuranceData }}>
                        <LayoutContext.Provider value={{ layout, setLayout }}>
                            <NotificationsContext.Provider value={{ notifications }}>
                                <>
                                    {initialized && initialized.branding && initialized.css ?
                                        <>
                                            <BrowserRouter>
                                                <Routes>
                                                    <Route path="/" element={<BaseLayout />}>
                                                        <Route index element={<DefaultLanding />} />
                                                        <Route path="welcome" element={<DefaultLanding />} />
                                                        {/* <Route index element={<screens.Payment />} /> */}
                                                        {/* <Route path="welcome" element={<screens.Quoter />} /> */}
                                                        <Route path="quoter" element={<screens.Quoter />} />
                                                        <Route path="general" element={<screens.General />} />
                                                        <Route path="introduction" element={<screens.Introduction />} />
                                                        <Route path="address" element={<screens.Address />} />
                                                        <Route path="diagnosis" element={<screens.Diagnosis />} />
                                                        <Route path="last10" element={<screens.Last10 />} />
                                                        <Route path="last5" element={<screens.Last5 />} />
                                                        <Route path="last2" element={<screens.Last2 />} />
                                                        <Route path="medical" element={<screens.Medical />} />
                                                        <Route path="occupation" element={<screens.Occupation />} />
                                                        <Route path="misc" element={<screens.Misc />} />
                                                        <Route path="beneficiary" element={<screens.Beneficiary />} />
                                                        <Route path="payment" element={<screens.Payment />} />
                                                        <Route path="consent" element={<screens.Consent />} />
                                                        <Route path="policy" element={<screens.Policy />} />
                                                        <Route path="review" element={<screens.Review />} />
                                                        <Route path="end" element={<screens.EjectionScreen />} />
                                                        <Route path="*" element={<DefaultLanding />} />
                                                    </Route>
                                                </Routes>
                                            </BrowserRouter>
                                        </>
                                        :
                                        <>&nbsp;</>
                                    }
                                </>
                            </NotificationsContext.Provider>
                        </LayoutContext.Provider>
                    </InsuranceDataContext.Provider>
                </BrandingContext.Provider>
            </GeoContext.Provider>
        </SessionContext.Provider>

    );
}

