import {
    useContext,
    useEffect,
    useReducer,
    useState,
} from 'react';

import {
    Button,
    Card,
    Col,
    Container,
    Form,
    Row,
} from 'react-bootstrap';

import { PreviousNextButtons } from '../controls/buttons';
import { RadioControl } from '../controls/radio';
import { TextControl } from '../controls/text';
import {
    asPhoneNumber,
    isEmail,
    isPhoneNumber,
} from '../libs/contact';
import {
    BrandingContext,
    GeoContext,
    InsuranceDataContext,
    LayoutContext,
    NotificationsContext,
    SessionContext,
    stateReducer,
} from '../libs/contexts';
import { scrollUp } from '../libs/ui';

export const DefaultLanding = () => {

    const { branding, setBranding } = useContext(BrandingContext);
    const { layout, setLayout } = useContext(LayoutContext);
    const { session, setSession } = useContext(SessionContext);

    const [updated, setUpdated] = useState(false);


    const validateScreen = ({ forceValidation }) => {



        if (forceValidation) {
            setLayout({
                lastValidated: "welcome"
            });
            setSession({ validationAttempted: Date.now() });
        }


    }

    useEffect(() => {

        scrollUp();
        setLayout({
            screenReady: false
        });

    }, []);

    useEffect(() => {

        if (updated && undefined !== layout.fields) {


            if (layout.fields && undefined === layout.fields[updated]) {
                let newFields = { ...layout.fields };
                newFields[updated] = "ok";
                setLayout({
                    fields: newFields
                });
            }

        }

    }, [updated, layout.fields]);

    const onChange = ({ id, value }) => {

        console.info(id, value);
        setUpdated(id);

        if ("phone" === id) {
            if (value && value.length > 3) {
                value = asPhoneNumber({ contactInfo: value });
                setSession({
                    phone: value,
                    asOf: Date.now()
                });
            }
        }

    }

    return (
        <>
            <Container>
                <Row className="mt-4 mb-4">
                    <Col>
                        <h3>Get your <span className="text-primary">instant</span> life insurance quote</h3>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col className="col-lg-12 col-xl-10">
                        <TextControl label="First name" title="Please provide us with your first name" field="first_name" placeholder="First name" onChange={onChange} required={true} type="text" />
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col className="col-lg-12 col-xl-10">
                        <TextControl label="Last name" title="Please provide us with your last name" field="last_name" placeholder="Last name" onChange={onChange} required={true} type="text" />
                    </Col>
                </Row>
                <Row>
                    <Col className="col-lg-12 col-xl-10">
                        <TextControl label="Phone number" title="Please provide us with the best number to contact you" field="phone" placeholder="Phone number" onChange={onChange} required={true} type="phone" />
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col className="col-lg-12 col-xl-10">
                        <TextControl label="Email address" title="Please provide us with the best email address to contact you" field="email" placeholder="Email address" onChange={onChange} required={true} type="email" />
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col className="col-lg-12 col-xl-10">
                        Do we have your consent to collect, use, and share the personal information you will provide to us as described in
                        our <a href={branding.privacyLink} rel="noopener noreferrer" target="_blank">Privacy Policy</a>?
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col>
                        <RadioControl label={false} type="yesno" helpMessage="We need your consent before continuing" onChange={onChange} field="privacy" expectedValue="Yes" />
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col>
                        {layout &&
                            <PreviousNextButtons enabled={layout.screenReady} callback={validateScreen} />
                        }
                    </Col>
                </Row>
            </Container>
        </>
    )

}
