import axios from 'axios';

export const sendRequest = ({ method, data, url, callback, token, raw, headers, timeout, verbose }) => {

    const defaultCallback = (response) => {
        if (verbose) {
            console.info(response);
        }
    }

    try {

        if (undefined === method) {
            method = 'get';
        }

        if (undefined === timeout) {
            timeout = 10000;
        }

        if (undefined === headers) {
            headers = {
                Accept: "application/json",
                "Content-Type": "application/json;charset=UTF-8",
            };
        }

        if (undefined !== token) {

            let sanitizedToken = token.split(' ').pop(); // in case "Bearer" is already in there
            headers.Authorization = 'Bearer ' + sanitizedToken;
        }


        let axiosParams = {
            headers: headers,
            method: method,
            url: url,
            timeout: undefined === timeout,
        };

        if (undefined !== data) {
            axiosParams.data = { ...data };
        }

        if (raw) {
            axiosParams.transformResponse = [];
        }

        if (verbose) {
            console.info("Params", axiosParams);
        }

        axios(axiosParams)
            .then(response => {

                if (callback) {
                    callback(response.data);
                } else {
                    if (verbose) {
                        console.info("Default callback invoked");
                    }
                    defaultCallback(response.data);
                }

                success = true;

            })
            .catch(error => {
                console.error('Axios error', error);
            })

        return true;
    }
    catch (err) {
        console.error(err);
        return false;
    }

}


