import {
    brokerBranding,
    brokerConfig,
} from '../config';

export const getBranding = ({ verbose }) => {


    let ab = selectProfile();
    let brandName = getBrandName();

    let branding;


    if (undefined !== brokerBranding[brandName]) {
        if (undefined !== brokerBranding[brandName][ab]) {
            branding = { ...brokerBranding[brandName][ab], ...brokerConfig };
            branding.ab = ab;
            branding.profile = ab;
            branding.brandName = brandName;

            // we're removing Quebec authorization regardless of broker config
            // see APPROVE-3448
            branding.authorized_qc = "no";

            if (verbose) {
                console.info("Branding", branding);
            }

        } else {
            console.warn("Missing branding data");
        }
    } else {
        console.warn("Unknown hostname");
    }

    return branding;

}

export const getBrandName = () => {
    // our "brand" is literally the domain name of the CDN (not to be confused with the domain for the api gateway)
    return window.location.hostname;
}


export const getBroker = () => {
    return brokerConfig.broker;
}

export const getCampaign = () => {

    let campaingInfo = {
        utm_campaign: 'default',
        utm_medium: 'default',
        utm_source: 'default'
    };

    const urlParams = new URLSearchParams(window.location.search);

    for (let [k] of Object.entries(campaingInfo)) {
        let value = urlParams.get(k);
        if (value) {
            campaingInfo[k] = value;
        }
    }

    return campaingInfo;
}

const selectProfile = () => {

    let ab = 'a';

    if (brokerConfig) {
        if (brokerConfig.split) {
            if (Math.random() > brokerConfig.split) {
                ab = 'b';
            }
        } else {
            console.warn("Missing a/b split");
        }
    } else {
        console.warn("Missing broker config");
    }

    return ab;

}
