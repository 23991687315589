import React, {
    useEffect,
    useState,
} from 'react';

export const Block1 = () => {
    return (
        <p>block #1.</p>
    );
}


export const Block2 = () => {
    return (
        <p>block #2.</p>
    );
}


export const Block3 = () => {
    return (
        <p>block #3.</p>
    );
}