export { Address } from './address.jsx';
export { Beneficiary } from './beneficiary.jsx';
export { Consent } from './consent.jsx';
export { Diagnosis } from './diagnosis.jsx';
export { General } from './general.jsx';
export { Introduction } from './introduction.jsx';
export { DefaultLanding } from './landing.jsx';
export { Last2 } from './last2.jsx';
export { Last5 } from './last5.jsx';
export { Last10 } from './last10.jsx';
export { Medical } from './medical.jsx';
export { Misc } from './misc.jsx';
export { Occupation } from './occupation.jsx';
export { Payment } from './payment.jsx';
export { Policy } from './policy.jsx';
export { Quoter } from './quoter.jsx';
export { Review } from './review.jsx';
export { EjectionScreen } from './oops.jsx';