import {
    useContext,
    useEffect,
    useState,
} from 'react';

import {
    Alert,
    Button,
    Card,
    Col,
    Container,
    Form,
    Image,
    ProgressBar,
    Row,
} from 'react-bootstrap';
import { AiOutlineLock } from 'react-icons/ai';
import { FaRegWindowClose } from 'react-icons/fa';
import { WiMoonAltThirdQuarter } from 'react-icons/wi';
import {
    Outlet,
    useLocation,
    useNavigate,
} from 'react-router-dom';

import * as config from '../config.js';
import {
    BrandingContext,
    GeoContext,
    InsuranceDataContext,
    LayoutContext,
    NotificationsContext,
    SessionContext,
    stateReducer,
} from '../libs/contexts';
import { validateScreenFields } from '../libs/workflow.js';
import { UiLayoutPlaceholder } from './placeholders';

export const BaseLayout = ({ device }) => {

    const navigate = useNavigate();
    let location = useLocation();

    const { layout, setLayout } = useContext(LayoutContext);
    const { session, setSession } = useContext(SessionContext);
    const [lastValidationUpdate, setLastValidationUpdate] = useState(false);


    useEffect(() => {


    }, []);

    useEffect(() => {


        if (lastValidationUpdate) {
            setLayout({
                fields: lastValidationUpdate
            });
        }

    }, [lastValidationUpdate]);

    useEffect(() => {

        /* here we intercept a jump */

        if (layout.currentScreen) {
            if (undefined !== session.jump_entry && "jump" === session.jump_entry) {
                if ("yes" === session.jumped) {
                    let mandatoryFields = ['first_name', 'last_name', 'email', 'phone'];
                    let nextPage = 'quoter';
                    for (let f of mandatoryFields) {
                        if (undefined === session[f] || "" === session[f]) {
                            nextPage = 'welcome';
                        }
                    }


                    setLayout({ specialView: "no" });
                    setSession({ jump_entry: "done" });

                    if ('welcome' !== nextPage) {
                        setLayout(
                            {
                                currentScreen: 'quoter'
                            }
                        );
                        // layout.currentScreen
                        navigate(nextPage);
                    }
                }

            }
        }




    }, [layout.currentScreen, session.jump_entry, session.jumped, session]);

    useEffect(() => {

        /* here we intercept bad navigation (such as people going directly to a specific screen) */
        let simpleUri;
        if ("/" === location.pathname) {
            simpleUri = "welcome";
        } else {
            simpleUri = location.pathname.split('/').pop();
        }

        if (simpleUri !== layout.currentScreen) {
            console.warn("We have a mismatch", location.pathname, layout.currentScreen);
            if (undefined === session.jump_entry) {
                navigate("/welcome");
            }
        }

        setSession({ latest_step: layout.currentScreen });



    }, [location.pathname, layout.currentScreen, session.jump_entry]);


    useEffect(() => {

        if (undefined !== layout.currentScreen && session.quote) {


            let validation = validateScreenFields({ screen: layout.currentScreen, session: session });
            setLayout({
                validation: validation
            });
            ///
            let allFields = [];

            if (validation && validation.valid && validation.valid.length > 0) {
                allFields = allFields.concat(validation.valid);
            }
            if (validation && validation.invalid && validation.invalid.length > 0) {
                allFields = allFields.concat(validation.invalid);
            }


            if (undefined === layout.fields) {
                let newFields = {};
                if (allFields) {
                    for (let field of allFields) {
                        newFields[field] = "unknown";
                    }
                }
                setLayout(
                    { fields: newFields }
                );
            } else {
                let newFields = {};
                let missing = false;
                let errors = false;
                for (let field of Object.keys(layout.fields)) {
                    let found = false;
                    if (validation.invalid && validation.invalid.includes(field)) {

                        if (layout.lastValidated && layout.lastValidated === layout.currentScreen) {
                            newFields[field] = "error";
                        } else {
                            newFields[field] = "unknown";
                        }

                        found = true;
                        errors = true;
                    } else {
                        if (validation.valid && validation.valid.includes(field)) {
                            newFields[field] = "ok";
                            found = true;
                        }
                    }
                    if (!found) {
                        missing = true;
                    }
                }

                if (JSON.stringify(layout.fields) !== JSON.stringify(newFields)) {
                    setLastValidationUpdate(newFields);
                }


                if (layout.fields && Object.keys(layout.fields).length > 0 && !missing) {
                    if (errors) {
                        if (layout.lastValidated && layout.lastValidated === layout.currentScreen) {
                            setLayout({
                                screenReady: !errors
                            });
                        }
                    } else {
                        setLayout({
                            screenReady: !errors
                        });
                        if (!errors) {
                            setLayout({
                                lastValidated: layout.currentScreen
                            });
                        }
                    }
                }

            }



        }

    }, [layout.currentScreen, layout.fields, layout.lastValidated, session]);



    return (
        <>
            {layout.name &&
                <UiLayoutPlaceholder MyOutlet={Outlet} layoutName={layout.name} />
            }
        </>
    )

}

const BaseDesktopProgressBar = () => {

    const { layout } = useContext(LayoutContext);
    const [progress, setProgress] = useState(10);
    const [color, setColor] = useState('primary');
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {

        if (layout.currentScreen && layout.screenSequence) {
            let screenCount = layout.screenSequence.length;
            let screenIndex = 0;
            for (let i = 0; i < screenCount; i++) {
                if (layout.currentScreen === layout.screenSequence[i]) {
                    screenIndex = i + 1;
                }
            }
            let screenDiv = screenIndex / (screenCount - 3);
            screenDiv = Math.ceil(100 * screenDiv);
            screenDiv = Math.round(screenDiv / 10) * 10;
            if (screenDiv > 90) {
                screenDiv = 95;
            }
            let newProgress = screenDiv;
            setProgress(newProgress);
        }

    }, [layout.currentScreen, layout.screenSequence]);

    useEffect(() => {

        if (layout.quoterView && "needs" === layout.quoterView) {
            setIsVisible(false);
        } else {
            setIsVisible(true);
        }

    }, [layout.quoterView]);


    //{ quoterView: "needs" }
    useEffect(() => {

        if (progress <= 10) {
            setColor('primary');
        } else {
            if (progress > 10 && progress <= 40) {
                setColor('info');
            } else {
                if (progress > 40 && progress <= 80) {
                    setColor('warning');
                } else {
                    setColor('success');
                }
            }
        }

    }, [progress]);

    return (
        <>
            {isVisible &&
                <ProgressBar title="If you complete the process, you will be insured in a matter of minutes" animated variant={color} now={progress} label={`${progress}%`} />
            }
        </>
    )

}

export const BaseDesktopRibbon = () => {

    const { layout, setLayout } = useContext(LayoutContext);
    const [hasButtons, setHasButtons] = useState({ back: true, forward: true });


    const navigate = useNavigate();

    useEffect(() => {

        const moveForward = () => {


            let nextScreen = layout.happyPath[layout.currentScreen];
            let newLayout = {
                currentScreen: nextScreen
            };
            setLayout(newLayout);
            navigate('/' + nextScreen);

        }

        const sendError = () => {

            if (layout.fields) {

                for (let [k, v] of Object.entries(layout.fields)) {
                    if ("invalid" === v) {
                        layout.fields[k] = "error";
                    }
                }
            }

        }

        let newHasButtons = {};

        if (["welcome", "consent", "policy"].includes(layout.currentScreen)) {
            newHasButtons.back = false;
        } else {
            newHasButtons.back = true;
        }

        if (["policy"].includes(layout.currentScreen)) {
            newHasButtons.forward = false;
        } else {
            newHasButtons.forward = true;
        }


        if (layout.requirements) {

            if (layout.requirements[layout.currentScreen]) {
                if ("pass" !== layout.requirements[layout.currentScreen]) {
                    let newLayout = {
                        buttonAction: sendError
                    }
                    setLayout(newLayout);
                    //newHasButtons.forward = false;
                } else {
                    let newLayout = {
                        buttonAction: moveForward
                    }
                    setLayout(newLayout);
                }
            }


        }


        setHasButtons(newHasButtons);


    }, [layout.currentScreen, layout.requirements, layout.fields]);

    const moveBack = () => {

        let newLayout = {
            currentScreen: layout.currentScreen
        };

        for (let [parent, child] of Object.entries(layout.happyPath)) {
            if (child === newLayout.currentScreen) {
                newLayout.currentScreen = parent;
            }
        }

        setLayout(newLayout);
        navigate('/' + newLayout.currentScreen);
    }

    const forwardClick = () => {

        if (layout.buttonAction) {
            layout.buttonAction();
        }
    }



    return (
        <>

            <Container fluid>
                <Row className="mt-4 mb-2">
                    <Col>
                        {hasButtons.back1 &&
                            <Button className="btn-lg" variant="outline-secondary" onClick={moveBack}>Previous</Button>
                        }
                    </Col>
                    <Col className="text-end text-right col-md-6">
                        {hasButtons.forward1 &&
                            <Button variant="outline-primary" className='btn-lg' onClick={forwardClick}>Continue</Button>
                        }
                    </Col>
                </Row>
                <Row className="mb-4 mt-3">
                    <Col>
                        {"jump" !== layout.specialView &&
                            <BaseDesktopProgressBar />
                        }
                    </Col>
                </Row>
            </Container>

        </>
    )


}

export const BaseDesktopFaq = () => {

    const { layout, setLayout } = useContext(LayoutContext);


    return (
        <Container>
            <Row>
                <hr />
                <Col>
                    <h5 id="faq-title">Frequently Asked Questions
                        <small className="float-end float-right clicker">
                            <FaRegWindowClose onClick={() => setLayout({ faq: "no" })} />
                        </small>

                    </h5>

                </Col>
            </Row>
            <Row>
                <hr />
                <Col>
                    <b>What's a premium waiver for total disability?</b>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p>
                        A waiver of premium for total disability is an optional benefit provision that is designed to waive the premium if the life insured becomes totally disabled prior to attaining age 60 and remains disabled for 4 consecutive months.
                    </p>
                </Col>
            </Row>
            <Row>
                <hr />
                <Col>
                    <b>I'm a smoker. Is that a problem?</b>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p>
                        Smoking does not disqualify you from getting insured but the premium will be adjusted to account for the additional risk.
                    </p>
                </Col>
            </Row>
            <Row>
                <hr />
                <Col>
                    <b>It says that the price is guaranteed for the entire term. What happens after that?</b>
                </Col>
            </Row>
            <Row>
                <Col>
                    <p>
                        You can remain insured but your monthly premiums will likely increase annually at the end of the 10 or 20-year term period. Don't worry, you will be notified ahead of time, and you can see the details of the rate increase in the premium schedule as part of your life policy contract.
                    </p>
                </Col>
            </Row>
            <Row className="mb-4">
                <hr />
                <Col>
                    <Button onClick={() => setLayout({ faq: "no" })} variant="outline-primary">Close</Button>
                </Col>
            </Row>
        </Container>
    );

}

export const BaseDesktopFooter = () => {

    const { branding } = useContext(BrandingContext);

    return (
        <>
            <Container fluid className="bg-light">
                <Row>
                    <Col>
                        <Container>
                            <Row className='mt-4 mb-2'>
                                <Col className='text-center text-muted'>
                                    <AiOutlineLock /> This connection is secure
                                </Col>
                            </Row>
                            <Row>
                                <Col className='text-center text-muted'>
                                    <a target="_blank" rel="noopener noreferrer" href={branding.privacyLink}>Legal/Privacy</a>
                                    &nbsp;|&nbsp;
                                    <a target="_blank" rel="noopener noreferrer" href={branding.accessibilityLink}>Accessibility policy</a>
                                    &nbsp;|&nbsp;
                                    <a target="_blank" rel="noopener noreferrer" href={branding.ftcLink}>Fair treatment of customers policy</a>
                                </Col>
                            </Row>
                            <Row className='mt-1 mb-4'>
                                <Col className='text-center'>
                                    <small>
                                        This life insurance quote is not meant for residents of Quebec
                                        &nbsp;--&nbsp;
                                        <i>Cette soumission d'assurance-vie n'est pas destinée aux résidents du Québec</i>
                                    </small>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export const BaseDesktopHeader = () => {

    const { branding } = useContext(BrandingContext);


    return (
        <>
            <div className="broker-logo">
                <a target="_blank" href={branding.website} rel="noopener noreferrer">
                    <Image className="broker-logo" src={config.assets + "/" + branding.logo} style={{ height: "" }} />
                </a>
            </div>

            <Container>
                <Row className="mt-2 mb-3">

                </Row>
            </Container>


        </>
    );
}

export const BaseDesktopMenu = () => {

    const { layout } = useContext(LayoutContext);
    const { session } = useContext(SessionContext);

    const swapTheme = () => {
        let rootElem = document.querySelector('#app');

        let elem = document.querySelector('#' + layout.cssId);
        if (elem.href) {
            let newCss;
            if (elem.href.includes('-dark')) {
                newCss = config.assets + '/bootstrap-light.min.css'; //elem.href.replace('-dark','-light');
            } else {
                newCss = config.assets + '/bootstrap-dark.min.css';
            }
            if (newCss) {
                rootElem.style.display = 'none';
                elem.href = newCss;
                setTimeout(() => {
                    rootElem.style.display = null;
                }, 800);
            }
        }
    }

    return (
        <Container fluid className="bg-dark text-white">
            <Row>
                <Col className='desktop-menu '>
                    <a title="Switch between dark and light mode" style={{ cursor: "pointer" }} onClick={swapTheme} className="float-right float-end" variant="outline-secondary"><WiMoonAltThirdQuarter /></a>

                    <Container>
                        <Row>
                            <Col>
                                &nbsp;

                            </Col>
                            <Col className="text-end text-right">
                                call us: {layout.phone}

                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Container>
    );

}