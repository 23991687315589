import {
    useContext,
    useEffect,
    useReducer,
    useState,
} from 'react';

import {
    Alert,
    Button,
    Card,
    Col,
    Container,
    Form,
    Row,
} from 'react-bootstrap';
import { BsCheckCircle } from 'react-icons/bs';

import { RadioControl } from '../controls/radio';
import { TextControl } from '../controls/text';
import {
    asPhoneNumber,
    isEmail,
    isPhoneNumber,
} from '../libs/contact';
import {
    BrandingContext,
    GeoContext,
    InsuranceDataContext,
    LayoutContext,
    NotificationsContext,
    SessionContext,
    stateReducer,
} from '../libs/contexts';
import { asCurrency } from '../libs/i18n';
import { scrollUp } from '../libs/ui';

export const NeedsAnalysis = ({ minFacevalue, maxFacevalue }) => {

    const { insuranceData } = useContext(InsuranceDataContext);
    const { branding, setBranding } = useContext(BrandingContext);
    const { layout, setLayout } = useContext(LayoutContext);
    const { session, setSession } = useContext(SessionContext);
    const [values, setValues] = useReducer(stateReducer, {
        debt: 0,
        existing: 0,
        perAnnum: 0,
        years: 0
    });
    const [coverage, setCoverage] = useState(0);
    const [ready, setReady] = useState(false);

    useEffect(() => {

        scrollUp();


    }, []);

    useEffect(() => {

        if (minFacevalue && maxFacevalue) {
            setReady(true);
        }

    }, [minFacevalue, maxFacevalue]);


    useEffect(() => {

        console.info("Needs coverage", session.needs_coverage);

    }, [session.needs_coverage]);



    useEffect(() => {

        if (undefined !== session.needs_existing && undefined !== session.needs_debt && undefined !== session.needs_perAnnum && undefined !== session.needs_years) {

            let years = 1;
            if (session.needs_years > 0) {
                years = session.needs_years;
            }
            let newCoverage = session.needs_debt + (session.needs_perAnnum * years) - session.needs_existing;
            if (newCoverage < 1) {
                newCoverage = 0;
            }
            console.info("Saving needs", newCoverage);
            setSession({
                needs_coverage: newCoverage
            });
        }

    }, [session.needs_existing, session.needs_debt, session.needs_perAnnum, session.needs_years]);


    const onChange = ({ id, value }) => {

        console.info("Change", id, value);

    }

    const goBack = () => {
        setLayout({ quoterView: "quoter" });
    }

    const saveContinue = () => {
        setSession({
            facevalue: session.needs_coverage
        });
        goBack();
    }



    return (
        <>
            <Container>
                <Row className="mt-4 mb-4">
                    <Col>
                        <h3>How much life insurance do you need?</h3>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col className="col-lg-12 col-xl-10">
                        <TextControl helpMessage={"Number of years for the replacement income."} minValue={0} maxValue={99} label="Income replacement (number of years)" title="Number of years for the replacement income." field="needs_years" hasErrors={false} onChange={onChange} required={true} type="number" />
                    </Col>
                </Row>
                <Row>
                    <Col className="col-lg-12 col-xl-10">
                        <TextControl slider={"hide"} helpMessage={"Income that your beneficiaries would require."} minValue={0} maxValue={10000000} label="Income replacement (amount per year)" title="Number of years for the replacement income." field="needs_perAnnum" hasErrors={false} onChange={onChange} required={true} type="dollar" />
                    </Col>
                </Row>
                <Row>
                    <Col className="col-lg-12 col-xl-10">
                        <TextControl slider={"hide"} helpMessage={"Current life insurance coverage (personal or group)."} minValue={0} maxValue={10000000} label="Current coverage" title="Number of years for the replacement income." field="needs_existing" hasErrors={false} onChange={onChange} required={true} type="dollar" />
                    </Col>
                </Row>
                <Row>
                    <Col className="col-lg-12 col-xl-10">
                        <TextControl slider={"hide"} helpMessage={"Final expenses, mortage balance, outstanding loans."} minValue={0} maxValue={10000000} label="Debt" title="Number of years for the replacement income." field="needs_debt" hasErrors={false} onChange={onChange} required={true} type="dollar" />
                    </Col>
                </Row>
                <Row>
                    <Col className="col-lg-12 col-xl-10">
                        {session.needs_coverage && session.needs_coverage > 0 ?
                            <>
                                {session.needs_coverage <= minFacevalue ?
                                    <Alert id="analysis-alert" variant="warning">
                                        Your insurance needs ({asCurrency({ amount: session.needs_coverage })}) are covered by the minimum policy value for this product.
                                    </Alert>
                                    :
                                    <>
                                        {session.needs_coverage <= maxFacevalue ?
                                            <Alert id="analysis-alert" variant="success">
                                                Suggested coverage: {asCurrency({ amount: session.needs_coverage })}
                                            </Alert>
                                            :
                                            <>

                                                <Card className="bg-light">
                                                    <Card.Header>
                                                        Important note
                                                    </Card.Header>
                                                    <Card.Body>
                                                        <Card.Title>About your insurance needs</Card.Title>


                                                        <p>
                                                            Your insurance needs ({asCurrency({ amount: session.needs_coverage })}) exceed the maximum policy value for this product.
                                                        </p>
                                                        <p>
                                                            Please contact us if you want to explore additional products that could provide more coverage: {layout.phone} or {layout.email}
                                                        </p>
                                                        <p>
                                                            Alternately, you can also proceed with the maximum policy value available with this product, which is {asCurrency({ amount: maxFacevalue })}
                                                        </p>



                                                    </Card.Body>
                                                </Card>
                                            </>

                                        }
                                    </>
                                }
                            </> :
                            <>

                            </>
                        }



                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col className="col-lg-12 col-xl-10">
                        <Button onClick={goBack} title="Cancel and go back" variant="outline-secondary" className="btn-lg">Cancel</Button>&nbsp;
                        <Button onClick={saveContinue} title="Click to select the proposed coverage" variant="outline-primary" className="btn-lg">Update</Button>
                    </Col>
                </Row>

            </Container>
        </>
    )

}

