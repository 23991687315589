import { useContext } from 'react';

import {
    Button,
    Card,
    Col,
    Container,
    Form,
    Row,
} from 'react-bootstrap';

import {
    BrandingContext,
    GeoContext,
    InsuranceDataContext,
    LayoutContext,
    NotificationsContext,
    SessionContext,
} from '../libs/contexts';

export const Last5 = () => {

    const { layout, setLayout } = useContext(LayoutContext);

    const validateScreen = () => {

        let requirements = { ...layout.requirements };
        requirements.last5 = "pass";

        let newLayout = {
            requirements: requirements
        };
        setLayout(newLayout);
    }

    return (
        <>
            this is the last5 screen
        </>
    )

}

