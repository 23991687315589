import {
    UiComponents,
    UiLayouts,
} from '../libs/ui';

export const UiPlaceholder = (props) => {
    let Component = UiComponents[props.block];
    return (<Component {...props} />);
}

export const UiLayoutPlaceholder = (props) => {
    let Component = UiLayouts[props.layoutName];
    return (<Component {...props} />);
}
