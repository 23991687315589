import {
    useContext,
    useEffect,
    useState,
} from 'react';

import {
    Button,
    Card,
    Col,
    Container,
    Form,
    Row,
} from 'react-bootstrap';

import { PreviousNextButtons } from '../controls/buttons';
import { RadioControl } from '../controls/radio';
import { TextControl } from '../controls/text';
import {
    HeightControl,
    WeightControl,
} from '../controls/units';
import {
    BrandingContext,
    GeoContext,
    InsuranceDataContext,
    LayoutContext,
    NotificationsContext,
    SessionContext,
} from '../libs/contexts';
import { scrollUp } from '../libs/ui';

export const General = () => {

    const { session, setSession } = useContext(SessionContext);
    const { layout, setLayout } = useContext(LayoutContext);
    const { insuranceData } = useContext(InsuranceDataContext);
    const [smoking, setSmoking] = useState({});
    const [mustRevalidate] = useState(false);

    const [ready, setReady] = useState(false);
    const [lifestyleQuestions, setLifestyleQuestions] = useState([]);


    const validateScreen = (options) => {


        if (undefined !== options && options.forceValidation) {
            setLayout({
                lastValidated: "general"
            });
            setSession({ validationAttempted: Date.now() });
        }


    }

    useEffect(() => {

        scrollUp();
        ///
        let requirements = { ...layout.requirements };
        requirements.general = "pass";

        let newLayout = {
            requirements: requirements
        };

        setLayout(newLayout);

    }, []);

    useEffect(() => {

        if (insuranceData && insuranceData.bundled) {

            if (insuranceData.bundled.lifestyle) {
                let newQuestions = [];
                for (let question of insuranceData.bundled.lifestyle.questions) {
                    let choices = {};
                    for (let [k, v] of Object.entries(question.choices)) {
                        choices[k] = v.copy;
                    }
                    question.choices = choices;
                    console.info("Add question", question);
                    newQuestions.push(question);
                }
                setLifestyleQuestions(newQuestions);
                setReady(true);
            }
            console.info(insuranceData.bundled);
        }

    }, [insuranceData]);

    useEffect(() => {

        console.info("Lifestyle questions", lifestyleQuestions);

    }, [lifestyleQuestions]);


    const onChange = (e) => {
        validateScreen();
    }



    return (
        <>
            <Form>
                <Container>
                    <Row className="mt-4 mb-4">
                        <Col>
                            <h3>About you</h3>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col className="col-lg-12 col-xl-10">
                            <HeightControl hasErrors={false} />
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col className="col-lg-12 col-xl-10">
                            <WeightControl hasErrors={false} />
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col className="col-lg-12 col-xl-10">
                            <TextControl label="Date of birth" title="Please provide us with your date of birth" field="dob" placeholder="yyyy-mm-dd" hasErrors={false} onChange={onChange} required={true} type="dob" />
                        </Col>
                    </Row>
                    {ready &&
                        <>
                            {lifestyleQuestions.map((question, index) =>
                                <Row className="mt-2" key={question.id}>
                                    <Col>
                                        <RadioControl label={question.copy} choices={question.choices} type="horizontal" onChange={onChange} field={"A" + question.id} />
                                    </Col>
                                </Row>
                            )}
                        </>
                    }
                    <Row className="mt-4">
                        <Col>
                            {layout &&
                                <PreviousNextButtons enabled={layout.screenReady} callback={validateScreen} />
                            }
                        </Col>
                    </Row>
                </Container>
            </Form>
        </>
    )

}

