import {
    useContext,
    useEffect,
    useState,
} from 'react';

import {
    Alert,
    Button,
    Card,
    Col,
    Container,
    Form,
    Image,
    InputGroup,
    Row,
} from 'react-bootstrap';
import { BsInfoCircle } from 'react-icons/bs';
import { CgCloseR } from 'react-icons/cg';

import {
    BrandingContext,
    GeoContext,
    InsuranceDataContext,
    LayoutContext,
    NotificationsContext,
    SessionContext,
} from '../libs/contexts';

export const RadioControl = ({ field, label, title, choices, expectedValue, mustRevalidate, onChange, required, helpMessage, type }) => {

    const { session, setSession } = useContext(SessionContext);
    const { layout } = useContext(LayoutContext);
    const [helpVisible, setHelpVisible] = useState(false);
    const [yesnoValues, setYesnoValues] = useState({});
    const [hasErrors, setHasErrors] = useState(false);

    useEffect(() => {

        let newYesnoValues = {
            "Yes": "Yes",
            "No": "No"
        }
        setYesnoValues(newYesnoValues);


    }, []);

    useEffect(() => {

        if (layout && layout.fields) {
            if ("error" === layout.fields[field]) {
                setHasErrors(true);
            } else {
                setHasErrors(false);
            }
        } else {
            setHasErrors(false);
        }


    }, [session[field], layout.fields]);

    const hasChanged = (e) => {
        let newSession = {};
        let value = e;
        newSession[field] = value;
        setSession(newSession);
        onChange({ id: field, value: value });
    }


    const onHelpToggle = () => {
        setHelpVisible(!helpVisible);
    }

    return (
        <Form.Group key={field} className="mb-3" controlId={field}>
            {label &&
                <>
                    <Form.Label>{label}

                    </Form.Label>
                    <>
                        {helpMessage &&
                            <>
                                {helpVisible ?
                                    <CgCloseR id={"help-close-" + field} className="float-end float-right clicker text-muted" onClick={onHelpToggle} />
                                    :
                                    <BsInfoCircle id={"help-open-" + field} className="float-end float-right clicker" onClick={onHelpToggle} />
                                }
                            </>
                        }
                    </>
                </>
            }

            <InputGroup className="mb-1" key={field}>

                {"horizontal" === type &&
                    <HorizontalRadio key={field} field={field} choices={choices} expectedValue={expectedValue} mustRevalidate={mustRevalidate} onChange={hasChanged} title={title} value={session[field]} hasErrors={hasErrors} required={required} />
                }
                {"yesno" === type &&
                    <HorizontalRadio key={field} field={field} choices={yesnoValues} expectedValue={expectedValue} mustRevalidate={mustRevalidate} onChange={hasChanged} title={title} value={session[field]} hasErrors={hasErrors} required={required} />
                }




            </InputGroup>

            {helpMessage && helpVisible &&
                <>
                    <div>
                        <Form.Text id={"help-label-" + field} className="text-muted">
                            {helpMessage}
                        </Form.Text>
                    </div>
                </>
            }

        </Form.Group>

    )

}

export const HorizontalRadio = ({ field, choices, hasErrors, expectedValue, mustRevalidate, onChange, helpMessage }) => {


    const { session, setSession } = useContext(SessionContext);
    const [hasWrongAnswer, setHasWrongAnswer] = useState(false);

    const [value, setValue] = useState("");


    const [options, setOptions] = useState(false);

    useEffect(() => {

        if (session[field]) {
            setValue(session[field]);
            if (undefined !== expectedValue) {
                if (session[field] !== expectedValue) {
                    setHasWrongAnswer(true);
                } else {
                    setHasWrongAnswer(false);
                }
            }
        }


    }, []);

    useEffect(() => {


        if ("" === value && undefined !== session[field]) {

            if (session[field] !== value) {
                setValue(session[field]);
            }
        }

    }, [session[field], value]);

    useEffect(() => {

        if (choices) {
            if (choices instanceof Map || choices instanceof Object) {
                let newOptions = [];
                if (choices instanceof Map) {
                    // we use a map when we need sorted objects
                    for (const [key, item] of choices.entries()) {
                        let newOption = {
                            id: key,
                            copy: item.copy
                        };
                        newOptions.push(newOption);
                    }
                }
                else {
                    // we use an object when we need simple k/v pairs
                    for (const [key, value] of Object.entries(choices)) {
                        let newOption = {
                            id: key,
                            copy: value
                        };
                        newOption[key] = value;
                        newOptions.push(newOption);
                    }
                }
                setOptions(newOptions);
            }
        }

    }, [choices]);


    useEffect(() => {

        if (mustRevalidate) {
            if (session[field]) {
                setValue(session[field]);
            }
            validateField();
        }

    }, [mustRevalidate]);



    const onValueChange = (e) => {

        if (undefined !== expectedValue) {
            if (expectedValue !== e) {
                setHasWrongAnswer(true);
            } else {
                setHasWrongAnswer(false);
            }
        }
        setValue(e);
        onChange(e);

    }




    return (
        <>

            <div key={field} className={hasErrors || hasWrongAnswer ? "text-danger font-italic mb-3" : "mb-3"}>

                {options &&
                    <>
                        {options.map((option, index) =>
                            <Form.Check
                                className={"choice-" + option.id}
                                key={option.id}
                                checked={value === option.id}
                                inline
                                label={option.copy}
                                name={field}
                                type="radio"
                                id={field + "-" + option.id}
                                onClick={() => onValueChange(option.id)}
                                onChange={() => onValueChange(option.id)}
                                aria-label={`${option.id}`}
                            />
                        )}
                    </>
                }



            </div>

        </>
    )

}


