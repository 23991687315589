import * as React from 'react';

import {
    Block1,
    Block2,
    Block3,
} from '../blocks';
import { DummyComponent } from '../components/dummy';
import { DefaultLanding } from '../components/landing';
import { BaseLayout } from '../layouts/base';
import { HeroLayout } from '../layouts/hero';
import { JumperLayout } from '../layouts/jumper';

export const getInitialLayoutInfo = ({ query }) => {

    let layoutInfo = {
        name: 'HeroLayout',
        query: query,
        params: {}
    };

    //if ("jump" == query.entry) {
    //    layoutInfo.name = "JumperLayout";
    //}

    return layoutInfo;

}

export const loadFavicon = ({ url }) => {
    let css = document.createElement("link");
    css.setAttribute("rel", "shortcut icon");
    css.setAttribute("href", url);
    document.getElementsByTagName("head")[0].appendChild(css)
}

//<link rel="preload" as="script" href="critical.js">

export const preloadStylesheet = ({ url }) => {
    let css = document.createElement("link");
    css.setAttribute("rel", "preload]");
    css.setAttribute("type", "text/css");
    css.setAttribute("as", "text/css");
    css.setAttribute("href", url);
    let cssId = url.split("/").pop().split('.').shift() + "-preload";
    css.setAttribute("id", cssId);
    document.getElementsByTagName("head")[0].appendChild(css);

}


export const loadStylesheet = ({ url, next }) => {
    let css = document.createElement("link");
    css.setAttribute("rel", "stylesheet");
    css.setAttribute("type", "text/css");
    css.setAttribute("href", url);
    let cssId = url.split("/").pop().split('.').shift();
    css.setAttribute("id", cssId);
    document.getElementsByTagName("head")[0].appendChild(css);

    const newLink = document.getElementById(cssId);
    newLink.onload = () => {
        next(cssId);
    };

}

export const getPages = () => {

    let pages = [
        {
            path: '/',
            element: <BaseLayout />
        },
        {
            path: '/welcome',
            element: <DefaultLanding />
        }
    ];

    for (let page of ['quoter', 'summary', 'medical']) {
        pages.push({
            path: page,
            element: <DummyComponent name={page} />
        });
    }
    return pages;
}

export const scrollUp = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
}

export const UiComponents = {
    Block1,
    Block2,
    Block3
};

export const UiLayouts = {
    HeroLayout,
    JumperLayout
};
