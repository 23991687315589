import {
    useContext,
    useEffect,
    useState,
} from 'react';

import {
    Alert,
    Button,
    Card,
    Col,
    Container,
    Form,
    Image,
    InputGroup,
    Row,
} from 'react-bootstrap';
import {
    Outlet,
    useLocation,
    useNavigate,
} from 'react-router-dom';

import {
    LayoutContext,
    SessionContext,
} from '../libs/contexts';
import { asNumber } from '../libs/i18n.js';

export const PreviousNextButtons = ({ enabled, callback }) => {

    const { session, setSession } = useContext(SessionContext);
    const { layout, setLayout } = useContext(LayoutContext);
    const [hasPrevious, setHasPrevious] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {

        if (layout.currentScreen) {
            if (['welcome', 'quoter', 'consent', 'policy'].includes(layout.currentScreen)) {
                setHasPrevious(false);
            } else {
                setHasPrevious(true);
            }
        }

    }, [layout.currentScreen]);

    const goBack = () => {

        let newLayout = {
            currentScreen: layout.currentScreen
        };

        for (let [parent, child] of Object.entries(layout.happyPath)) {
            if (child === newLayout.currentScreen) {
                newLayout.currentScreen = parent;
            }
        }

        setLayout(newLayout);
        navigate('/' + newLayout.currentScreen);
    }

    const moveForward = () => {
        let nextScreen = layout.happyPath[layout.currentScreen];
        let newLayout = {
            currentScreen: nextScreen
        };
        setLayout(newLayout);
        navigate('/' + nextScreen);
    }

    const nextAction = () => {
        if (enabled) {
            moveForward();
        } else {
            callback({ forceValidation: true });
        }
    }

    return (

        <>
            <Container fluid>
                <Row>
                    {hasPrevious &&
                        <Col>
                            <Button variant="outline-secondary" className='btn-lg'>Back</Button>
                        </Col>
                    }
                    <Col className='text-end text-right'>
                        <Button onClick={nextAction} variant={enabled ? "outline-primary" : "outline-secondary"} className='btn-lg'>Continue</Button>
                    </Col>
                </Row>
            </Container>
        </>

    )

}

