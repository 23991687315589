import {
    useContext,
    useEffect,
    useReducer,
    useState,
} from 'react';

import {
    Accordion,
    Button,
    Card,
    Col,
    Container,
    Form,
    Row,
} from 'react-bootstrap';

import { PreviousNextButtons } from '../controls/buttons';
import { RadioControl } from '../controls/radio';
import { TextControl } from '../controls/text';
import {
    AccordionBasic,
    RadioAccordion,
} from '../controls/wizards';
import {
    asPhoneNumber,
    isEmail,
    isPhoneNumber,
} from '../libs/contact';
import {
    BrandingContext,
    GeoContext,
    InsuranceDataContext,
    LayoutContext,
    NotificationsContext,
    SessionContext,
    stateReducer,
} from '../libs/contexts';
import { scrollUp } from '../libs/ui';
import { extractQuestions } from '../libs/workflow';

export const Occupation = () => {

    const { branding, setBranding } = useContext(BrandingContext);
    const { layout, setLayout } = useContext(LayoutContext);
    const { session, setSession } = useContext(SessionContext);
    const { insuranceData } = useContext(InsuranceDataContext);
    const [parentQuestion, setParentQuestion] = useState(false);
    const [occupations, setOccupations] = useState(false);
    const [outcome, setOutcome] = useState("incomplete");


    const [clicked, setClicked] = useState(0);
    const [heading, setHeading] = useState("");


    const [viewMode, setViewMode] = useState("list");




    const [updated, setUpdated] = useState(false);


    const validateScreen = ({ forceValidation }) => {



        if (forceValidation) {
            setLayout({
                lastValidated: "welcome"
            });
            setSession({ validationAttempted: Date.now() });
        }


    }

    useEffect(() => {

        scrollUp();
        setLayout({
            screenReady: false
        });

    }, []);

    useEffect(() => {

        console.info("outcome", outcome);

    }, [outcome]);

    useEffect(() => {

        if (undefined !== insuranceData.bundled && undefined !== insuranceData.bundled["occupation"]) {
            let questions = extractQuestions(insuranceData.bundled["occupation"], "carousel");
            for (let question of questions) {
                if (question.bullets) {

                    let newOccupations = [[], []];
                    for (let i = 0; i < question.bullets.length; i++) {

                        let subQuestion = {
                            copy: question.bullets[i],
                            id: i,
                            parentId: question.id,
                            yes: question.yes,
                            no: question.no
                        }

                        if (i <= question.bullets.length / 2) {
                            newOccupations[0].push(subQuestion);
                        } else {
                            newOccupations[1].push(subQuestion);
                        }

                    }

                    setOccupations(newOccupations);
                    setParentQuestion(question);
                    setHeading(question.copy);
                }
            }
        }

    }, [insuranceData]);


    useEffect(() => {

        if (updated && undefined !== layout.fields) {


            if (layout.fields && undefined === layout.fields[updated]) {
                let newFields = { ...layout.fields };
                newFields[updated] = "ok";
                setLayout({
                    fields: newFields
                });
            }

        }

    }, [updated, layout.fields]);

    const onChange = ({ id, value }) => {

        console.info(id, value);
        setUpdated(id);

        if ("phone" === id) {
            if (value && value.length > 3) {
                value = asPhoneNumber({ contactInfo: value });
                setSession({
                    phone: value,
                    asOf: Date.now()
                });
            }
        }

    }

    return (
        <>
            <Container>
                <Row className="mt-4 mb-4">
                    <Col>
                        <h3>Occupation</h3>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col className="col-lg-12 col-xl-10">
                        {occupations &&
                            <RadioAccordion questions={occupations} setOutcome={setOutcome} />
                        }
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col>
                        {layout &&
                            <PreviousNextButtons enabled={layout.screenReady} callback={validateScreen} />
                        }
                    </Col>
                </Row>
            </Container>
        </>
    )

}
