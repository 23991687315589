import {
    useContext,
    useEffect,
    useState,
} from 'react';

import {
    Button,
    Card,
    Col,
    Container,
    Form,
    Row,
} from 'react-bootstrap';

import { PreviousNextButtons } from '../controls/buttons';
import { RadioControl } from '../controls/radio';
import { TextControl } from '../controls/text';
import {
    BrandingContext,
    GeoContext,
    InsuranceDataContext,
    LayoutContext,
    NotificationsContext,
    SessionContext,
} from '../libs/contexts';
import { scrollUp } from '../libs/ui';

export const Introduction = () => {

    const { session, setSession } = useContext(SessionContext);
    const { layout, setLayout } = useContext(LayoutContext);
    const { insuranceData } = useContext(InsuranceDataContext);
    const [smoking, setSmoking] = useState({});
    const [mustRevalidate] = useState(false);

    const [qualifyingQuestions, setQualifyingQuestions] = useState([]);

    useEffect(() => {

        scrollUp();
        ///
        let requirements = { ...layout.requirements };
        requirements.introduction = "pass";

        let newLayout = {
            requirements: requirements
        };

        setLayout(newLayout);

    }, []);

    useEffect(() => {

        if (insuranceData && insuranceData.bundled) {
            if (insuranceData.bundled.statement) {
                let newQuestions = insuranceData.bundled.statement.questions;
                setQualifyingQuestions(newQuestions);
            }
            console.info(insuranceData.bundled);
        }

    }, [insuranceData]);


    const onChange = (e) => {
        validateScreen();
    }

    const validateScreen = () => {

        let requirements = { ...layout.requirements };
        requirements.introduction = "pass";

        let newLayout = {
            requirements: requirements
        };
        setLayout(newLayout);
    }

    return (
        <>
            <Form>
                <Container>
                    <Row className="mt-4 mb-4">
                        <Col>
                            <h3>General information</h3>
                        </Col>
                    </Row>
                    {qualifyingQuestions.map((question, index) =>
                        <Row className="mt-2" key={question.id}>
                            <Col>
                                <RadioControl label={question.copy} type="yesno" onChange={onChange} field={"A" + question.id} />
                            </Col>
                        </Row>
                    )}
                    <Row className="mt-4">
                        <Col>
                            {layout &&
                                <PreviousNextButtons enabled={layout.screenReady} callback={validateScreen} />
                            }
                        </Col>
                    </Row>
                </Container>
            </Form>
        </>
    )

}

